import { get } from 'lodash';
import React, {  } from 'react'
import ReactSpeedometer from "react-d3-speedometer"




const CseSpeedo = (props) => {
const {avarageCseData} = props;

const avgValue1 =  JSON.stringify((get(avarageCseData,"averageCes",0)));
const avgValue = avgValue1 == 0 ? 0 : avgValue1 - 1


if(avarageCseData.color_code){
return (
  <ReactSpeedometer className="d-block mx-auto"
  width={450}
  maxValue={5}
  value={avgValue}
  fontSize="12px"
  needleHeightRatio={0.61}
  needleTransition
  needleColor={"#015590"}
   currentValueText={avgValue1}
  customSegmentLabels={[
    {
      text: "(1) Very Bad",
      position: "INSIDE",
      color: "#070707",
      fontSize:'13px'
    },
    {
      text: "(2) Bad",
      position: "INSIDE",
      color: "#070707",
      fontSize:'13px'
    },
    {
      text: "(3) Neutral",
      position: "INSIDE",
      color: "#070707",
      fontSize:'13px',
      
    },
    {
      text: "(4) Good",
      position: "INSIDE",
      color: "#070707",
      fontSize:'13px',
    },
    {
      text: "(5) Very Good",
      position: "INSIDE",
      color: "#070707",
      fontSize:'13px',
    },
  ]}
/>
    )
}else{
  return (
    <div className="chart-height-small-1">please wait...</div>
  )
}
  }
    export default CseSpeedo;