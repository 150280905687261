import React,{Component} from 'react';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = { 
     }
  }


  
onChange =(dates, dateStrings)=> {
  console.log("datess-->",dates, dateStrings,);
  const {onChange,rangeValue} = this.props;
  if(dates){
    onChange(
      dateStrings[0],
      dateStrings[1],
      dates[2]  
    )
  
  }
  else{
     onChange(
      '',
      '',
      'custom_range'
    )
  }
 }

 disabledDate = current => {
  return current && moment(current).add('days') > moment().endOf("day");
};

onPanelChange=(value,mode)=>{
  console.log("value,mode->",value,mode);
  }
  render() { 
    

const {DateStart,DateEnd,rangeValue} = this.props;
const { RangePicker } = DatePicker;

console.log("rangeValue->",rangeValue)



    return ( 
        <Space className="mb-2" direction="vertical" size={12}>
        <RangePicker 
        disabledDate={this.disabledDate}
        //  value={rangeValue}
          ranges={{
            'Today': [moment(), moment(),'today'],
            'Yesterday': [moment().subtract(1,'day'), moment().subtract(1,'day'),'yesterday'],
            'Week' : [moment().startOf('week'),moment().endOf('week'),'week'],
            'This Month': [moment().startOf('month'), moment(),'current_month'],
            'Last Month': [moment().subtract(1,'months').startOf('month'), moment().subtract(1,'months').endOf('month'),'last_month'],
            '1st Quarter' : [moment().month(0).startOf('month'),moment().month(2).endOf('month'),'quarter_1'],
            '2nd Quarter' : [moment().month(3).startOf('month'),moment().month(5).endOf('month'),'quarter_2'],
            '3rd Quarter' : [moment().month(6).startOf('month'),moment().month(8).endOf('month'),'quarter_3'],
            '4th Quarter' : [moment().month(9).startOf('month'),moment().month(11).endOf('month'),'quarter_4'],
            '1th Half Yearly' : [moment().month(0).startOf('month'),moment().month(5).endOf('month'),'half_year_1'],
            '2nd Half Yearly' : [moment().month(6).startOf('month'),moment().month(11).endOf('month'),'half_year_2'],
            'Yearly' : [moment().month(0).startOf('month'),moment().month(11).endOf('month'),'year'],
          }}
          onChange={this.onChange}
        />
        
      </Space>
     );
}
}
export default DateRangePicker;

