
const BASE_URL =  "https://lindeces.qdegrees.com:3003" 
export const baseUrl = 'https://lindeces.qdegrees.com:3003'
const config = {
  login: `${BASE_URL}/auth/admin/local/`,
  lastUpdateResult:`${BASE_URL}/ivr/last-refresh`,
  ivrScoreAndPercent: `${BASE_URL}/ivr/scoreAndPercent`,
  ivrwordCloud: `${BASE_URL}/ivr/wordCloud`,
  ivrList: `${BASE_URL}/ivr/ivrList`,
  ivrCatAndSubCat: `${BASE_URL}/ivr/main-cat-and-sub-cat`,
  ivrTotalResult: `${BASE_URL}/ivr/totalResult`,
  ivrDownload: `${BASE_URL}/ivr/downloadDump`,
  ivrAgentList: `${BASE_URL}/common/all-agent-list`,
  ivrPlantList: `${BASE_URL}/common/all-plant-list`,
  ivrRatingList: `${BASE_URL}/common/rating-list`,
  ivrScoreList: `${BASE_URL}/ivr/call-transferred-res-received `,
  ivrCountryList: `${BASE_URL}/common/country`,
  ivrSegmentList: `${BASE_URL}/common/segment-list `,
  ivrCseScore: `${BASE_URL}/ivr/ces-score`,
  ivrCustomerRating: `${BASE_URL}/ivr/customer-rating`,
  ivrAvarageCse: `${BASE_URL}/ivr/average-ces `,
  ivrCountryScore: `${BASE_URL}/ivr/country-score-card`,
  ivrAgentDetail: `${BASE_URL}/ivr/agent-detail-list`,
  ivrPlantDetail: `${BASE_URL}/ivr/plant-detail-list `, 
  ivrPlantDetailDownload: `${BASE_URL}/ivr/plant-detail-download `, 
  ivrAgentDetailDownload: `${BASE_URL}/ivr/agent-detail-download`, 
  settingAccessible: `${BASE_URL}/setting/setting-is-accessable`, 
  accessibleTabsAccessible: `${BASE_URL}/setting/accessible-tabs`, 
  customerCodeList:`${BASE_URL}/common/customer-code-list`, 
  modifyDataList:`${BASE_URL}/add_ivr/get-modified-data`, 
  ivrAllDataList:`${BASE_URL}/add_ivr/get-save-data`, 
  modifyIvrDataList:`${BASE_URL}/add_ivr/get-ivr-modified-data`, 
  modifyIvrUpdateDataList:`${BASE_URL}/add_ivr/update-ivr-data`, 
  
}



export const overrideLoaderCss = "display: block;margin:18% auto;border-color: #f4ae00;";
export const loaderColorCode = "#f4ae00";

const messages = {
  noResMsg: "NO RESPONSE FOUND",
  catchErr: { status: false, errors: 'Something went wrong' },
}

export { config, messages } 