import React, { useState, useEffect } from 'react';
import { css } from "@emotion/react";
import {ClipLoader,BarLoader} from "react-spinners";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #005590;
  color:"#005590";
`;

const IvrLoader = (props) => {

    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#005590");


    return ( 
        <div className="sweet-loading">
        <BarLoader speedMultiplier={1.3} css={override} color={"blue"}  height={4} loading={loading} size={100} />
      </div>
     );
}
 
export default IvrLoader;