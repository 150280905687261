import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter, HashRouter,Route, Redirect, Switch} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App/index';
import * as serviceWorker from './serviceWorker';
import rootReducer from './store/_reducer';
import config from './config';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { createStore,applyMiddleware,compose } from 'redux'
import SignUp1 from './Demo/Authentication/SignUp/SignUp1';
import SignIn1 from './Demo/Authentication/SignIn/SignIn1';

import DashboardDefaults from './Demo/Dashboard/Defaults'
import DashboardIvr from './Demo/Dashboard/Ivr'
import DashboardWebform from './Demo/Dashboard/Webform'
import DashboardAgentFeedback from './Demo/Dashboard/AgentFeedback'

const middleWare = [thunk];
const store = createStore(rootReducer, compose(applyMiddleware(...middleWare,logger)))



 

const MainApp = () => {
return(
    <Provider store={store}>
        {/* <BrowserRouter basename={config.basename}> */}
        
        <ToastContainer />
            
            <App />
            {/* <Switch>
            <Route exact path='/auth/signin-1' render={(props) => (<SignIn1   {...props}/>)} />
            <Route exact path='/auth/signun-1' render={(props) => (<SignUp1   {...props}/>)} />
            <Route path='/dashboard/defaults' render={() => (<DashboardDefaults />)} />
                                                    <Route path='/dashboard/ivr' render={() => (<DashboardIvr />)} />
                                                    <Route path='/dashboard/webform' render={() => (<DashboardWebform />)} />
                                                    <Route path='/dashboard/agentFeedback' render={() => (<DashboardAgentFeedback />)} />
                                                   
            </Switch> */}
       
        {/* </BrowserRouter> */}
    </Provider>
)
        };

ReactDOM.render(<MainApp/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
