import { combineReducers } from 'redux';
import { users } from './users.reducer';
import { ivr } from './ivr.reducer';
import reducer from '../_reducer/reducer'


const rootReducer = combineReducers({
  reducer,users,ivr,
});

export default rootReducer;
