import React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import windowSize from 'react-window-size';
import './../../../assets/scss/style.scss';
import Aux from "../../../hoc/_Aux";
import Breadcrumb from "../../../App/layout/AdminLayout/Breadcrumb";
import { Form, Input, Button, Checkbox } from 'antd';
import Group12 from '../../../assets/images/Group12.png';
import LindeLogo from '../../../assets/images/linde-logo.png';
import bgImage from '../../../assets/images/bhg.png';
import { bindActionCreators } from 'redux';
import {loginAction,setToken,settingAction,setIsAccessable,setIvrRecordingAccess} from '../../../store/_action/userAuth.action'
import { toast } from 'react-toastify';
import { withCookies } from 'react-cookie';

class SignIn1 extends React.Component {
  state = {
        passwordShown: false,
        username: '',
        password:''
    };

    passwordVisiblity = () => {
        this.setState({
            passwordShown: this.state.passwordShown  ? false : true
        });
      };
  
    
      loginSubmit = async (values) => {
       await this.props.loginAction(values)
      let result = this.props.userRes.loginRes
       if (result.code === 200) {
          await this.props.cookies.set('token',result.data.token)
          await localStorage.setItem('token',result.data.token)
          await localStorage.setItem('username',result.data.user.username)
          await this.props.setToken(result.data.token)
         await this.setting(result.data.token)
          window.location.href='/#/dashboard/defaults'
          toast.success(result.message)
        } else {
          
          toast.error(result.response.data.message === "username must be a valid email" ? "Email ID not valid" : result.response.data.message)
        }
      }
setting = async(token)=>{

  console.log(token,"==>");
  await this.props.settingAction(token)
  let resultSetting = this.props.userRes.settingAccessibleRes
  if (resultSetting && resultSetting.code === 200) {
    console.log(resultSetting,'resultSetting');
    await this.props.cookies.set('isAccessable',resultSetting.data.is_accessable)
    await this.props.cookies.set('ivrRecordingAccess',resultSetting.data.ivr_recording_access)
    await this.props.setIsAccessable(resultSetting.data.is_accessable)
    await this.props.setIvrRecordingAccess(resultSetting.data.ivr_recording_access)
    
  } else {
    
    }
}

    render () {
      // const {loading} = this.props.userRes.loading
        return(
            <Aux>
                <Breadcrumb/>
                <div style={{backgroundImage:`url(${bgImage})`,}} className="reward">
      <div className="container-fluid reward d-flex align-items-center justify-content-center">
        <div className="col-lg-8 mx-auto shadw bg-white">
          <div className="row">
            <div className="col-lg-6 bg-card-login align-items-center d-flex px-0">
              <img src={Group12} className="col-lg-12 px-0" />
            </div>
            <div className="position-relative col-lg-6 d-flex align-items-center justify-content-center">
              <div>
                <img src={LindeLogo} className="position-absolute login-img"  alt="Los Angeles" /></div>
              <div className="col-lg-12 mt-4">
                <div className="card-body mt-5">
                  <h4 className="clr-txt clr-txt-h4">Welcome Back !</h4>
                  <p className="clr-txt font-light mb-4">Please Login To Your Account ...</p>

                  <Form onFinish={this.loginSubmit}
                  >
                    <Form.Item
                    autoComplete="off"
                    className="mb-4 "
                    name="username"
                    value={this.state.username}
                      rules={[
                        {
                          required: true,
                          message: 'Enter Email ID',
                        },
                      ]}
                    >
                      <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-transparent form-field"><i className="fa fa-envelope" aria-hidden="true"/></span>
                    </div>
                      <Input type="text"  autoComplete="off"
                      className="form-control py-3  rounded-0" 
                       placeholder="Email Address... " 
                      /> 
                    </div> 
                    </Form.Item>

                    <Form.Item
                    autoComplete="off"
                    className="mb-3 "
                      name="password"
                      value={this.state.password} 
                      rules={[
                        {
                          required: true,
                          message: 'Enter password!',
                        },
                      ]}
                    >
                     <div className="input-group position-relative ">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-transparent form-field"><i className="fa fa-key" aria-hidden="true"/></span>
                    </div>
                    <Input  autoComplete="off"  type={this.state.passwordShown ? "text" : "password"}
                    className="form-control py-3 form-field rounded-0"  
                      placeholder="Password..." 
                    />
                  
                   
                  </div> 
                  
                    </Form.Item>
                    <span className="input-group-btn eye-style">
                    <button className="  btn bg-transparent bg-eye reveal" type="button"><i onMouseEnter={this.passwordVisiblity} onMouseLeave={this.passwordVisiblity} className={this.state.passwordShown ?"fa fa-eye":"fa fa-eye-slash"}></i></button>
                  </span>
                    
                    <div className="form-footer d-flex justify-content-between align-items-center mt-4">
                      <button className="btn btn-primary col-lg-6 mb-0 text-white mx-auto btn-gradient py-2" type="submit">Login</button>
                    </div>
                  </Form>
                  
                
                  <p className="text-center mt-4">For Any Assistance, Kindly Contact Us On <br />
                  support@qdegrees.com</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
                    {/* <div className="auth-content">
                        <div className="auth-bg">
                            <span className="r"/>
                            <span className="r s"/>
                            <span className="r s"/>
                            <span className="r"/>
                        </div>
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="mb-4">
                                    <i className="feather icon-unlock auth-icon"/>
                                </div>
                                <h3 className="mb-4">Login</h3>
                                <div className="input-group mb-3">
                                    <input type="email" className="form-control" placeholder="Email"/>
                                </div>
                                <div className="input-group mb-4">
                                    <input type="password" className="form-control" placeholder="password"/>
                                </div>
                                <div className="form-group text-left">
                                    <div className="checkbox checkbox-fill d-inline">
                                        <input type="checkbox" name="checkbox-fill-1" id="checkbox-fill-a1" />
                                            <label htmlFor="checkbox-fill-a1" className="cr"> Save credentials</label>
                                    </div>
                                </div>
                                <button className="btn btn-primary shadow-2 mb-4">Login</button>
                                <p className="mb-2 text-muted">Forgot password? <NavLink to="/auth/reset-password-1">Reset</NavLink></p>
                                <p className="mb-0 text-muted">Don’t have an account? <NavLink to="/auth/signup-1">Signup</NavLink></p>
                            </div>
                        </div>
                    </div>
                */}
                
            </Aux>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userRes: state.users
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { loginAction,setToken,settingAction,setIsAccessable,setIvrRecordingAccess  },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(withCookies(SignIn1)));