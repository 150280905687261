import React,{Fragment} from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import WordCloud from "highcharts/modules/wordcloud.src.js"
import Exporting from "highcharts/modules/exporting.js"
import ExportData from "highcharts/modules/export-data.js"


ExportData(Highcharts);
Exporting(Highcharts);
WordCloud(Highcharts);


const NewMtdChart = (props) => {

  const {Categories,ColumnData,GetIvrScoreData} = props;

  const clicked = (e) => {
    const {date_type,start_date,end_date} = e.point.options;
    
    GetIvrScoreData("drildown",date_type,start_date,end_date)
  console.log("e.point.options",date_type,start_date,end_date);
    

  }
        const options = {
            chart: {
              type: 'column',
              
              style: {
                cursor: 'pointer',
            }
            },
            title: {
              text: ""//"name" //`${Name} ${"|"} ${partnerName}`, 
            },
            credits: {
                enabled: false
            },
            xAxis:[ {
              title: {
                text: ""//'Date with daily counts'
              },
              type: 'category',
              categories: Categories,
             // ["May-30", "May-31", "Jun-01", "Jun-02", "Jun-03", "Jun-05", "Jun-06"],//DateAndCount,
              labels: {
                  rotation: 0,
                  style: {
                      fontSize: '13px',
                      fontFamily: 'Verdana, sans-serif',
                      color:"#015590"
                  }
              }
            }],
            yAxis: { 
              gridLineWidth: 0,
              // max:100,
              // min: 0,
              title: {
                  text: ''
              },
              labels: {
                enabled: false
            },
            },
            legend: {
              enabled: false
            },
            exporting: {
              enabled: false
            },
            tooltip: {
              shared: true
            },
              series: 
              [{
                colorByPoint: true,
                  name: 'Response Received',
                  data: ColumnData,//NpsScore,//UpiMtd,
                  // [
                  //   {y: 0,date_type:"week",start_date:"30-May-2021",end_date:"30-May-2021", className: "highcharts-mtd-chart"},
                  //  {y: 6, className: "highcharts-mtd-chart"},
                  //  {y: 0, className: "highcharts-mtd-chart"},
                  //  {y: 0, className: "highcharts-mtd-chart"},
                  //  {y: 0, className: "highcharts-mtd-chart"},
                  //  {y: 0, className: "highcharts-mtd-chart"},
                  //  {y: 0, className: "highcharts-mtd-chart"},
                  // ],
                  events: {
                    click:  (e)=> {
                      clicked(e)
                    }
                  },
                  dataLabels: {
                    min:0,
                      enabled: true,
                      rotation: 0,
                      color: 'black',
                      align: 'center',
                      y: 5, // 10 pixels down from the top
                      style: {
                          fontSize: '13px',
                          fontFamily: 'Verdana, sans-serif',
                      }
                  }
              },
            //   {
            //     type: 'spline',
            //     name: 'Responses Recevied',
            //     data: [0,6,0,0,0,0,0],
            //     marker: {
            //         lineWidth: 1,
            //         lineColor: Highcharts.getOptions().colors[3],
            //         fillColor: 'blue'
            //     },
            //     events: {
            //       click:  (e)=> {
            //         clicked(e)
            //       }
            //     },
            // },
              
            ]
            }
    return ( 
        <Fragment>

        <HighchartsReact 
          highcharts={Highcharts}
          options={options}
        />
   
      </Fragment>
     );
}
 
export default NewMtdChart;