import React from 'react';
import Pagination from '@material-ui/lab/Pagination';

const PaginationTable = (props) => {

    const {Page, TotalPage,HandlePageChange} = props;

   
    // const itemRender=(current, type, originalElement)=> {
    //     console.log("current->",current,originalElement);
    //     if (type === 'prev') {
    //       return <a>Previous</a>;
    //     }
    //     if (type === 'next') {
    //       return <a>Next</a>;
    //     }
    //     return originalElement;
    //   }
    return ( 
        <div className="text-center mb-4">
            <Pagination count={TotalPage} page={Page} onChange={HandlePageChange}  
          variant="outlined" color="info" align="center"  />
        </div>
     );
}
 
export default PaginationTable;