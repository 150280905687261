import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import DatamapsIndia from 'react-datamaps-india'




const IndiaMapChart = (props) => {

  const {countryScore} = props;
  const [popUp,setPop] = useState('');

console.log("countryScore=>",countryScore);

    return ( 
      <DatamapsIndia  
      regionData={
        {
          countryScore
        }
      }
     hoverComponent={({ value }) => {
     
              return (
                <>
                  <p>{get(value,"name","")}</p>
                  <p>CES Score : {get(value,"total_responses",0) + get(value,"count",0) +"%"}</p>
                  <p>Top Count: {get(value,"total_easy",0) + "|" + get(value,"total_easy_percent",0) +"%" }</p>
                  <p>Neutral Count: {get(value,"total_neutral",0)+ "|" + get(value,"total_neutral_percent",0) +"%" }</p>
                  <p>Bottom Count: {get(value,"total_difficult",0)+ "|" + get(value,"total_difficult_percent",0) +"%"  }</p>
                </>
              );
            }}
      mapLayout={{
        title: '',
        // legendTitle: 'Legend Title',
        startColor: '#FF471A',
        endColor: '#6AD72D',
        hoverTitle: 'Count',
        noDataColor: '#f5f5f5',
        borderColor: '#8D8D8D',
        hoverBorderColor: '#8D8D8D',
        hoverColor: '#add8e6',
      }}
    />
     );
}
 
export default IndiaMapChart;