export const userConstants = {
    AUTH_REQUEST:'AUTH_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    SET_TOKEN: 'USERS_SET_TOKEN',
    GET_TOKEN: 'USERS_GET_TOKEN',
    SET_ACCESSIBLE: 'USERS_SET_ACCESSIBLE',
    GET_ACCESSIBLE: 'USERS_GET_ACCESSIBLE',
    SET_IVR_RECORDING_ACCESSIBLE: 'USERS_SET_IVR_RECORDING_ACCESSIBLE',
    GET_IVR_RECORDING_ACCESSIBLE: 'USERS_GET_IVR_RECORDING_ACCESSIBLE',
    LOGOUT: 'USERS_LOGOUT',
    LAST_UPDATE_SUCCESS:'LAST_UPDATE_SUCCESS',
    LAST_UPDATE_LOADING:'LAST_UPDATE_LOADING',
    SETTING_ACCESSIBLE_SUCCESS:'SETTING_ACCESSIBLE_SUCCESS',
    SETTING_ACCESSIBLE_TAB_SUCCESS:'SETTING_ACCESSIBLE_TAB_SUCCESS',
};
