import React from 'react';
import { Row, Col, Card, Table, Tabs, Tab, Dropdown } from 'react-bootstrap';
import { Label, Button, Input } from 'reactstrap';
import Aux from "../../hoc/_Aux";
import DEMO from "../../store/_constant/constant";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import windowSize from 'react-window-size';
import { currentWeek } from '../../common/commonComponent/common';
import avatar1 from '../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../assets/images/user/avatar-2.jpg';
import avatar3 from '../../assets/images/user/avatar-3.jpg';
import avatar4 from '../../assets/images/agent-logo.png';
import Plant from '../../assets/images/plant.png';
import WordCloudChart from '../../common/charts/wordCloud';
import CategorySubcat from '../../common/charts/categorySubcat';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    ivrAction, ivrWordCloudAction, ivrCountryListAction, ivrCatAndSubCatAction,
    ivrListAction, ivrTotalResultAction, ivrDownloadAction, ivrPlantListAction, ivrAgentListAction,
    ivrRatingListAction, ivrScoreAction, ivrSegmentAction, ivrCustomerRatingAction, ivrCseScoreAction,
    ivrAvarageCseAction, ivrPlantDetailAction, ivrAgentDetailAction, ivrCountryScoreAction,
    ivrAgentDetailDownloadAction, ivrPlantDetailDownloadAction,
} from '../../store/_action/Ivr.action'
import { lastUpdateAction } from '../../store/_action/userAuth.action';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { baseUrl, overrideLoaderCss, loaderColorCode } from '../../store/_config';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClipLoader } from 'react-spinners';
import { CSVLink, CSVDownload } from "react-csv";
import DateRangePicker from '../../common/commonComponent/DateRangePicker';
import ColoumBarLineChart from '../../common/charts/coloumBarLineChart';
import CseSpeedo from '../../common/charts/cseSpeedo';
import IndiaMapChart from '../../common/charts/indiaMapChart';
import PolarChart from '../../common/charts/polarChart';
import CanvasColumnChart from '../../common/charts/canvas/canvasColumnChart';
import DialerChart from '../../common/charts/dialerChart';
import { MultiSelect } from "react-multi-select-component";
import PaginationTable from '../../common/commonComponent/PaginationTable';
import ViewMoreModal from './viewMoreModal';
import NewMtdChart from '../../common/charts/newMtdChart';
import { Spin, Space } from 'antd';
import IvrLoader from '../../common/Loader';
import IvrRingLoader from '../../common/ringLoader';


let zoneList = [
    { label: 'East', value: "East" },
    { label: 'West', value: "West" },
    { label: 'North', value: "North" },
    { label: 'South', value: "South" },
]
class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ivrResult: [],
            cseScore: {},
            wordCloudResult: {},
            catAndSubCatResult: [],
            ivrListResult: {},
            ivrListFinalResult: [],
            languageChange: false,
            totalResult: {},
            error: false,
            hasMore: true,
            isLoading: false,
            isLoadingAgent: false,
            isLoadingPlant: false,
            isLoadingWordCloud: false,
            isLoadingMtdChart: false,
            isLoadingCseDialer: false,
            isLoadingCustomerRating: false,
            isLoadingAvgCse: false,
            isLoadingIndiaMap: false,
            isLoadingCatSub: false,
            isLoadingDownloadDump: false,
            isLoadingTotalResult: false,
            page: 1,
            downloadDump: [],
            downloadDumpSeparateData: [],
            downloadDumpId: '',
            startDate: "",
            endDate: "",
            countryList: [{ label: 'India' }],
            country: [],
            agentList: [],
            agentName: [],
            plantList: [],
            plantName: [],
            ratingList: [],
            score: [],
            rating: [],
            dateType: 'week',
            segmentList: [],
            segment: [],
            zone: [],
            cseScore: {},
            customerRating: {},
            selectChartPolor: '',
            selectChart: "All",
            avarageCseData: {},
            agentDetail: [],
            plantDetail: [],
            countryScore: [],
            totalPageAgent: 1,
            pageAgent: 1,
            totalPagePlant: 1,
            pagePlant: 1,
            plantListDownload: [],
            agentListDownload: [],
            mainCat: '',
            scrollKey: {
                scrollTop: 0,
                innerHeight: 0,
                offsetHeight: 0
            },
            modal: false,
            showAllCategory: false
        }
    }

    componentDidMount() {
        this.getIVRData()
        this.getIVRWordCloudData()
        this.getIVRCatAndSubCatResData()
        this.getIVRListData()
        this.getIVRTotalResultsData()
        this.getIVRDownloadData()
        this.getIvrCountryListData()
        this.getIvrAgentListData([])
        this.getIvrRatingListData()
        this.getIvrScoreData()
        this.getIvrPlantListData([])
        this.getIvrSegmentListData()
        this.getCseScoreData()
        this.getCustomerRatingData()
        this.getIvrAvarageCseData()
        this.getIVRAgentDetailData()
        this.getIVRPlantDetailData()
        this.getIVRCountryScoreData()
        this.getIVRAgentListDownloadData()
        this.getIVRPlantListDownloadData()
        window.addEventListener('scroll', this.scrollMore, true);
    }
    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.scrollMore,true);
    // }

    componentDidUpdate(_, prevState) {
        if (prevState.pagePlant !== this.state.pagePlant) {
            this.getIVRPlantDetailData()
        }
        if (prevState.pageAgent !== this.state.pageAgent) {
            this.getIVRAgentDetailData()
        }
        if (prevState.selectChart !== this.state.selectChart) {
            this.getIVRWordCloudData()
        }
        if (prevState.selectChartPolor !== this.state.selectChartPolor) {
            this.getIVRCatAndSubCatResData()
        }

    }



    getIVRData = async () => {
        await this.props.ivrAction({
            "country": "INDIA"
        })
        let result = this.props.ivrReducer.ivrRes
        if (result && result.code === 200) {
            this.setState({
                ivrResult: result.data
            })

        } else {

            toast.error(get(result, 'data.message', ''))
        }
    }

    getIVRWordCloudData = async () => {

        this.setState({ isLoadingWordCloud: true })
        const { selectChart } = this.state;
        let wordObj = {
            title: "IVR Feedback",
            ces_type: selectChart == "All" ? ["All"] : selectChart,
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,

        }
        if (wordObj.start_date === "") {
            delete wordObj.start_date
        }
        if (wordObj.end_date === "") {
            delete wordObj.end_date
        }
        await this.props.ivrWordCloudAction(wordObj)
        let result = this.props.ivrReducer.ivrWordCloudRes
        if (result && result.code === 200) {
            this.setState({
                wordCloudResult: result.data,
                isLoadingWordCloud: false
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getIVRAgentDetailData = async () => {
        const { pageAgent, isLoadingAgent } = this.state
        const agentDetailObj = {
            title: "IVR Feedback",
            page_no: pageAgent,
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
        }
        if (agentDetailObj.start_date === "") {
            delete agentDetailObj.start_date
        }
        if (agentDetailObj.end_date === "") {
            delete agentDetailObj.end_date
        }
        await this.props.ivrAgentDetailAction(agentDetailObj)
        let result = this.props.ivrReducer.ivrAgentDetail
        if (result && result.code === 200) {
            this.setState({
                agentDetail: result.data,
                totalPageAgent: result.data.total_page,
                isLoadingAgent: false,
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getIVRPlantDetailData = async () => {

        const { pagePlant, isLoadingPlant } = this.state
        const plantDetailObj = {
            title: "IVR Feedback",
            page_no: pagePlant,
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
        }
        if (plantDetailObj.start_date === "") {
            delete plantDetailObj.start_date
        }
        if (plantDetailObj.end_date === "") {
            delete plantDetailObj.end_date
        }
        await this.props.ivrPlantDetailAction(plantDetailObj)
        let result = this.props.ivrReducer.ivrPlantDetail
        if (result && result.code === 200) {
            this.setState({
                plantDetail: result.data,
                totalPagePlant: result.data.total_page,
                isLoadingPlant: false,
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getIVRCountryScoreData = async () => {
        this.setState({ isLoadingIndiaMap: true })
        const countryScoreObj = {
            title: "IVR Feedback",
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
        }
        if (countryScoreObj.start_date === "") {
            delete countryScoreObj.start_date
        }
        if (countryScoreObj.end_date === "") {
            delete countryScoreObj.end_date
        }
        await this.props.ivrCountryScoreAction(countryScoreObj)
        let result = this.props.ivrReducer.ivrCountryScore
        if (result && result.code === 200) {
            this.setState({
                countryScore: result.data,
                isLoadingIndiaMap: false,
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getIvrCountryListData = async () => {
        await this.props.ivrCountryListAction([])
        let result = this.props.ivrReducer.ivrCountryList
        if (result && result.code === 200) {

            this.setState({
                countryList: result.data.map((ele) => ({ value: ele._id, label: ele.title }))
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getIvrAgentListData = async (plant_id) => {
        const { plantName } = this.state;
        console.log(plant_id, 'plant_id.length', plant_id.length);

        let finalPlant = []
        const agentObj = {
            title: "IVR Feedback",
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
        }
        if (plant_id.length > 0) {
            plant_id.forEach(element => {
                finalPlant.push(element.value)
            });
            agentObj.plant_id = finalPlant
        }
        if (agentObj.start_date === "") {
            delete agentObj.start_date
        }
        if (agentObj.end_date === "") {
            delete agentObj.end_date
        }
        await this.props.ivrAgentListAction(agentObj)
        let result = this.props.ivrReducer.ivrAgentList
        if (result && result.code === 200) {

            this.setState({
                agentList: result.data.map((ele) => ({ label: ele._id, value: ele._id }))
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getIvrPlantListData = async (agent_id) => {
        console.log(agent_id, 'agent_id.length', agent_id.length);
        let finalAgent = []
        const plantObj = {
            title: "IVR Feedback"
        }
        if (agent_id.length > 0) {
            agent_id.forEach(element => {
                finalAgent.push(element.value)
            });
            plantObj.agent_id = finalAgent
        }
        await this.props.ivrPlantListAction(plantObj)
        let result = this.props.ivrReducer.ivrPlantList
        if (result && result.code === 200) {

            this.setState({
                plantList: result.data.map((ele) => ({ label: ele._id, value: ele._id }))
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getIvrSegmentListData = async () => {
        const segmentObj = {
            title: "IVR Feedback"
        }
        await this.props.ivrSegmentAction(segmentObj)
        let result = this.props.ivrReducer.ivrSegmentList

        if (result && result.code === 200) {

            this.setState({
                segmentList: result.data.map((ele) => ({ label: ele.title, value: ele.title }))
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getIvrRatingListData = async () => {
        const ratingObj = {
            title: "IVR Feedback"
        }
        await this.props.ivrRatingListAction(ratingObj)
        let result = this.props.ivrReducer.ivrRatingList
        if (result && result.code === 200) {

            this.setState({
                ratingList: result.data.map((ele) => ({ label: ele, value: ele }))
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getIvrAvarageCseData = async () => {
        this.setState({

            isLoadingAvgCse: true,
        })
        const avarageCseObj = {
            title: "IVR Feedback",
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
        }
        if (avarageCseObj.start_date === "") {
            delete avarageCseObj.start_date
        }
        if (avarageCseObj.end_date === "") {
            delete avarageCseObj.end_date
        }
        await this.props.ivrAvarageCseAction(avarageCseObj)
        let result = this.props.ivrReducer.ivrAvarageCse
        if (result && result.code === 200) {

            this.setState({
                avarageCseData: result.data,
                isLoadingAvgCse: false,
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getIvrScoreData = async (type, date_type, start_date, end_date) => {
        this.setState({ isLoadingMtdChart: true })
        const { dateType,
            startDate,
            endDate,
            segment,
            rating,
            zone,
            agentName,
            plantName, country, } = this.state;
        let finalSegment = []
        let finalRating = []
        let finalZone = []
        let finalAgentName = []
        let finalPlantName = []
        let finalCountryName = []
        segment.forEach(element => { finalSegment.push(element.value) });
        rating.forEach(element => { finalRating.push(element.value) });
        zone.forEach(element => { finalZone.push(element.value) });
        plantName.forEach(element => { finalPlantName.push(element.value) });
        agentName.forEach(element => { finalAgentName.push(element.value) });
        country.forEach(element => { finalCountryName.push(element.value) });

        let ratingObj;
        if (type === 'drildown') {
            this.setState({
                ...this.state,
                dateType: date_type,
                startDate: start_date,
                endDate: end_date,
            })
            ratingObj =
            {
                date_type: dateType,
                start_date: startDate,
                end_date: endDate,
                segment: finalSegment,
                rating: finalRating,
                zone: finalZone,
                agent_id: finalAgentName,
                plant_id: finalPlantName,

            }

        }

        if (type === 'Reset') {
            this.setState({
                ...this.state,
                dateType: 'week',
                startDate: "",
                endDate: "",
            })
            ratingObj = {
                date_type: dateType,
                start_date: startDate,
                end_date: endDate,
                segment: finalSegment,
                rating: finalRating,
                zone: finalZone,
                agent_id: finalAgentName,
                plant_id: finalPlantName,
                country_id: finalCountryName,
            }
        }

        ratingObj = {
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            segment: finalSegment,
            rating: finalRating,
            zone: finalZone,
            agent_id: finalAgentName,
            plant_id: finalPlantName,
            country_id: finalCountryName,
        }
        if (ratingObj.start_date === "") {
            delete ratingObj.start_date
        }
        if (ratingObj.end_date === "") {
            delete ratingObj.end_date
        }
        await this.props.ivrScoreAction(ratingObj)
        let result = this.props.ivrReducer.ivrScore
        if (result && result.code === 200) {
            this.setState({
                score: result.data,
                isLoadingMtdChart: false
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getCseScoreData = async () => {
        const ratingObj = {
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            segment: this.state.segment,
            rating: this.state.rating,
            zone: this.state.zone,
            agent_id: this.state.agentName,
            plant_id: this.state.plantName,
        }
        if (ratingObj.start_date === "") {
            delete ratingObj.start_date
        }
        if (ratingObj.end_date === "") {
            delete ratingObj.end_date
        }
        await this.props.ivrCseScoreAction(ratingObj)
        let result = this.props.ivrReducer.ivrCseScore
        if (result && result.code === 200) {
            this.setState({
                cseScore: result.data
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getCustomerRatingData = async () => {
        this.setState({
            isLoadingCustomerRating: true
        })
        const ratingObj = {
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            segment: this.state.segment,
            rating: this.state.rating,
            zone: this.state.zone,
            agent_id: this.state.agentName,
            plant_id: this.state.plantName,
        }
        if (ratingObj.start_date === "") {
            delete ratingObj.start_date
        }
        if (ratingObj.end_date === "") {
            delete ratingObj.end_date
        }
        await this.props.ivrCustomerRatingAction(ratingObj)
        let result = this.props.ivrReducer.ivrCustomerRating
        if (result && result.code === 200) {
            this.setState({
                customerRating: result.data,
                isLoadingCustomerRating: false,
            })
        } else {

            // toast.error(get(result,'data.message',''))
        }
    }
    getIVRCatAndSubCatResData = async () => {
        this.setState({ isLoadingCatSub: true })
        const { selectChartPolor } = this.state;
        const catAndSubCatObj = {
            title: "IVR Feedback",
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            main_cat: selectChartPolor
        }
        if (catAndSubCatObj.main_cat === "") {
            delete catAndSubCatObj.main_cat
        }
        if (catAndSubCatObj.start_date === "") {
            delete catAndSubCatObj.start_date
        }
        if (catAndSubCatObj.end_date === "") {
            delete catAndSubCatObj.end_date
        }
        await this.props.ivrCatAndSubCatAction(catAndSubCatObj)
        let result = this.props.ivrReducer.ivrCatAndSubCatRes
        if (result && result.code === 200) {
            this.setState({
                catAndSubCatResult: get(result, 'data', []),
                isLoadingCatSub: false,
            })
        } else {
            toast.error(get(result, 'data.message', ''))
        }

    }
    getIVRListData = async () => {
        this.setState({ isLoading: true })
        const { dateType,
            startDate,
            endDate,
            segment,
            rating,
            zone,
            agentName,
            plantName, country } = this.state;
        let finalSegment = []
        let finalRating = []
        let finalZone = []
        let finalAgentName = []
        let finalPlantName = []
        let finalCountryName = []
        segment.forEach(element => { finalSegment.push(element.value) });
        rating.forEach(element => { finalRating.push(element.value) });
        zone.forEach(element => { finalZone.push(element.value) });
        plantName.forEach(element => { finalPlantName.push(element.value) });
        agentName.forEach(element => { finalAgentName.push(element.value) });
        country.forEach(element => { finalCountryName.push(element.value) });
        const listObj = {
            date_type: dateType,
            start_date: startDate,
            end_date: endDate,
            segment: finalSegment,
            rating: finalRating,
            zone: finalZone,
            agent_id: finalAgentName,
            plant_id: finalPlantName,
            country_id: finalCountryName,
            page_no: this.state.page,
        }

        if (listObj.agent_id === []) {
            delete listObj.agent_id
        }
        if (listObj.plant_id === []) {
            delete listObj.plant_id
        }

        if (listObj.country_id === []) {
            delete listObj.country_id
        }

        if (listObj.segment === []) {
            delete listObj.segment
        }

        if (listObj.rating === []) {
            delete listObj.rating
        }

        if (listObj.zone === []) {
            delete listObj.zone
        }
        if (listObj.start_date === "") {
            delete listObj.start_date
        }
        if (listObj.end_date === "") {
            delete listObj.end_date
        }



        await this.props.ivrListAction(listObj)

        let result = this.props.ivrReducer.ivrListRes

        if (result && result.code === 200) {

            get(result, 'data', {}).finalResult.forEach(element => {
                element.language = "0"
            });
            console.log((result.data.currentPage < result.data.totalPage));
            const nextPageRecord = get(result, 'data', {}).finalResult;
            this.setState({
                ivrListResult: get(result, 'data', {}),
                hasMore: (result.data.currentPage <= result.data.totalPage),
                isLoading: false,
                ivrListFinalResult: [
                    ...this.state.ivrListFinalResult,
                    ...nextPageRecord,
                ],
            })

        } else {
            this.setState({
                isLoading: false,
                hasMore: false
            })
            // toast.error(get(result,'data.message',''))
        }

        // setTimeout(() => {
        //     this.setState({
        //       items: this.state &&  this.state.ivrListResult&& this.state.ivrListResult.finalResult.concat(Array({ length: 2 }))
        //     });
        //   }, 1000);
    }
    scrollMore = () => {
        const {
            getIVRListData,
            state: {
                error,
                isLoading,
                hasMore,
            },
        } = this;
        // Bails early if:
        // * there's an error
        // * it's already loading
        // * there's nothing left to load
        console.log("error scroll->>>>>>>>>", error, isLoading, !hasMore,);
        if (error || isLoading || !hasMore)

            return;

        // Checks that the page has scrolled to the bottom
        // console.log("scroll2222 ----======->",document.documentElement.scrollTop);
        if (
            (document.documentElement.scrollTop > this.state.scrollKey.scrollTop && document.documentElement.scrollTop > 3500)
        ) {

            var page_no = this.state.page;
            page_no = parseInt(page_no) + 1;
            this.setState({
                page: page_no,
                scrollKey: {
                    "scrollTop": document.documentElement.scrollTop,
                    "innerHeight": window.innerHeight,
                    "offsetHeight": document.documentElement.offsetHeight
                }
            }, () => {
                getIVRListData();
            })
        }
    }
    getIVRTotalResultsData = async () => {
        this.setState({ isLoadingTotalResult: true })
        const totalResultObj = {
            title: "IVR Feedback",
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
        }
        if (totalResultObj.start_date === "") {
            delete totalResultObj.start_date
        }
        if (totalResultObj.end_date === "") {
            delete totalResultObj.end_date
        }
        await this.props.ivrTotalResultAction(totalResultObj)
        let result = this.props.ivrReducer.ivrTotalResults
        if (result && result.code === 200) {
            this.setState({
                totalResult: get(result, 'data', {}),
                isLoadingTotalResult: false
            })
        } else {

            toast.error(get(result, 'data.message', ''))
        }
    }
    changeLanguage = (e, index) => {
        let temp = [...this.state.ivrListResult.finalResult]
        temp[index].language = e.target.id
        this.setState({ ...this.state.ivrListResult, finalResult: temp })
        console.log(e.target.target, index);
    }

    getIVRDownloadData = async () => {
        let Id = this.state.downloadDumpId
        let dataObj = {
            id: Id,
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
        }
        if (dataObj.id === "") {
            delete dataObj.id
        }
        if (dataObj.start_date === "") {
            delete dataObj.start_date
        }
        if (dataObj.end_date === "") {
            delete dataObj.end_date
        }
        await this.props.ivrDownloadAction(dataObj)

        let result = this.props.ivrReducer.ivrDownload
        if (result && result.code === 200) {
            this.setState({
                downloadDump: get(result, 'data', []),
            })
        } else {

            toast.error(get(result, 'data.message', ''))
        }
    }

    getIVRAgentListDownloadData = async () => {
        this.setState({ isLoadingAgent: true })
        let dataObj = {
            title: "IVR Feedback",
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
        }
        if (dataObj.start_date === "") {
            delete dataObj.start_date
        }
        if (dataObj.end_date === "") {
            delete dataObj.end_date
        }
        await this.props.ivrAgentDetailDownloadAction(dataObj)

        let result = this.props.ivrReducer.ivrAgentDetailDownload
        if (result && result.code === 200) {
            this.setState({
                agentListDownload: result.data,
                isLoadingAgent: false,
            })

        } else {

            toast.error(get(result, 'message', ''))
        }
    }
    getIVRPlantListDownloadData = async () => {
        this.setState({ isLoadingPlant: true })
        let dataObj = {
            title: "IVR Feedback",
            date_type: this.state.dateType,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
        }
        if (dataObj.start_date === "") {
            delete dataObj.start_date
        }
        if (dataObj.end_date === "") {
            delete dataObj.end_date
        }
        await this.props.ivrPlantDetailDownloadAction(dataObj)

        let result = this.props.ivrReducer.ivrPlantDetailDownload
        if (result && result.code === 200) {
            this.setState({
                plantListDownload: result.data,
                isLoadingPlant: false
            })

        } else {

            toast.error(get(result, 'message', ''))
        }
    }
    handleChangeMultiSelect = (name, value) => {
        switch (name) {
            case 'agentName':

                this.getIvrPlantListData(value)
                break;


            case 'plantName':
                this.getIvrAgentListData(value)
                break;

            default:
                break;
        }

        this.setState({
            [name]: value
        })
    }


    handleDateChange = (startDate, endDate, dateType) => {
        this.setState({
            startDate, endDate, dateType
        })
    }
    getlastUpdatedData = async () => {
        await this.props.lastUpdateAction({})

    }


    appliedFilter = async (type) => {
        this.setState({
            isLoadingCustomerRating: true,
            isLoadingAgent: true,
            isLoadingPlant: true,
            isLoadingWordCloud: true,
            isLoadingMtdChart: true,
            isLoadingCseDialer: true,
            isLoadingAvgCse: true,
            isLoadingIndiaMap: true,
            isLoadingCatSub: true,
            isLoadingDownloadDump: true,
            isLoadingTotalResult: true,
        })
        const { dateType,
            startDate,
            endDate,
            segment,
            rating,
            zone,
            agentName,
            plantName, country } = this.state;
        let finalSegment = []
        let finalRating = []
        let finalZone = []
        let finalAgentName = []
        let finalPlantName = []
        let finalCountryName = []
        this.setState({
            error: false,
            hasMore: true,
            isLoading: false,
            page: 1,
            ivrListResult: [],
            ivrListFinalResult: [],
            scrollKey: {
                scrollTop: 0,
                innerHeight: 0,
                offsetHeight: 0
            },
        }, async () => {
            segment.forEach(element => { finalSegment.push(element.value) });
            rating.forEach(element => { finalRating.push(element.value) });
            zone.forEach(element => { finalZone.push(element.value) });
            plantName.forEach(element => { finalPlantName.push(element.value) });
            agentName.forEach(element => { finalAgentName.push(element.value) });
            country.forEach(element => { finalCountryName.push(element.value) });
            if (type === "apply") {
                const ratingObj = {
                    country_id: finalCountryName,
                    date_type: dateType === undefined ? 'custom_range' : dateType,
                    start_date: startDate === "" ? "" : startDate,
                    end_date: endDate === "" ? "" : endDate,
                    segment: finalSegment,
                    rating: finalRating,
                    zone: finalZone,
                    agent_id: finalAgentName,
                    plant_id: finalPlantName,
                }

                const ratingObjData = {
                    country_id: finalCountryName,
                    date_type: dateType === undefined ? 'custom_range' : dateType,
                    start_date: startDate === "" ? "" : startDate,
                    end_date: endDate === "" ? "" : endDate,
                    segment: finalSegment,
                    rating: finalRating,
                    zone: finalZone,
                    agent_id: finalAgentName,
                    plant_id: finalPlantName,
                    page_no: 1,
                }

                if (ratingObjData.start_date === "") {
                    delete ratingObjData.start_date
                }
                if (ratingObjData.end_date === "") {
                    delete ratingObjData.end_date
                }
                if (ratingObj.start_date === "") {
                    delete ratingObj.start_date
                }
                if (ratingObj.end_date === "") {
                    delete ratingObj.end_date
                }
                // score action //
                await this.props.ivrScoreAction(ratingObj)
                let result = this.props.ivrReducer.ivrScore
                if (result && result.code === 200) {
                    this.setState({
                        score: result.data,
                        isLoadingMtdChart: false,
                    })
                } else {

                    toast.error(get(result, 'message', ''))
                }

                // cse Score //
                await this.props.ivrCseScoreAction(ratingObj)
                let resultCse = this.props.ivrReducer.ivrCseScore
                if (resultCse && resultCse.code === 200) {
                    this.setState({
                        cseScore: resultCse.data,
                        isLoadingCseDialer: false
                    })
                } else {

                    // toast.error(get(result,'data.message',''))
                }
                await this.props.ivrCustomerRatingAction(ratingObj)
                let resultCustomerRating = this.props.ivrReducer.ivrCustomerRating
                if (resultCustomerRating && resultCustomerRating.code === 200) {
                    this.setState({
                        customerRating: resultCustomerRating.data,
                        isLoadingCustomerRating: false,

                    })
                } else {

                    // toast.error(get(result,'data.message',''))
                }
                await this.props.ivrAvarageCseAction(ratingObj)
                let resultAvarageCse = this.props.ivrReducer.ivrAvarageCse
                if (resultAvarageCse && resultAvarageCse.code === 200) {

                    this.setState({
                        avarageCseData: resultAvarageCse.data,
                        isLoadingAvgCse: false,

                    })
                } else {

                    // toast.error(get(result,'data.message',''))
                }
                await this.props.ivrAgentDetailAction(ratingObj)
                let resultAgentDetail = this.props.ivrReducer.ivrAgentDetail
                if (resultAgentDetail && resultAgentDetail.code === 200) {
                    this.setState({
                        agentDetail: resultAgentDetail.data,
                        totalPageAgent: resultAgentDetail.data.total_page,
                        isLoadingAgent: false,
                    })
                } else {

                    // toast.error(get(result,'data.message',''))
                }
                await this.props.ivrPlantDetailAction(ratingObj)
                let resultPlantDetail = this.props.ivrReducer.ivrPlantDetail
                if (resultPlantDetail && resultPlantDetail.code === 200) {
                    this.setState({
                        plantDetail: resultPlantDetail.data,
                        totalPagePlant: resultPlantDetail.data.total_page,
                        isLoadingPlant: false,
                    })
                } else {

                    // toast.error(get(result,'data.message',''))
                }
                await this.props.ivrCountryScoreAction(ratingObj)
                let resultCountryScore = this.props.ivrReducer.ivrCountryScore
                if (resultCountryScore && resultCountryScore.code === 200) {
                    this.setState({
                        countryScore: resultCountryScore.data,
                        isLoadingIndiaMap: false,
                    })
                } else {

                    // toast.error(get(result,'data.message',''))
                }

                await this.props.ivrPlantDetailDownloadAction(ratingObj)

                let plantListResult = this.props.ivrReducer.ivrPlantDetailDownload
                if (plantListResult && plantListResult.code === 200) {
                    this.setState({
                        plantListDownload: plantListResult.data,
                    })

                } else {

                    toast.error(get(plantListResult, 'message', ''))
                }
                await this.props.ivrAgentDetailDownloadAction(ratingObj)

                let resultAgentDetailDownload = this.props.ivrReducer.ivrAgentDetailDownload
                if (resultAgentDetailDownload && resultAgentDetailDownload.code === 200) {
                    this.setState({
                        agentListDownload: resultAgentDetailDownload.data,
                    })

                } else {

                    toast.error(get(resultAgentDetailDownload, 'message', ''))
                }

                await this.props.ivrDownloadAction(ratingObj)

                let resultDownload = this.props.ivrReducer.ivrDownload
                if (resultDownload && resultDownload.code === 200) {
                    this.setState({
                        downloadDump: get(resultDownload, 'data', []),
                        isLoadingDownloadDump: false,
                    })
                } else {

                    toast.error(get(resultDownload, 'data.message', ''))
                }
                await this.props.ivrCatAndSubCatAction(ratingObj)
                let resultIvrCatAndSubCat = this.props.ivrReducer.ivrCatAndSubCatRes
                if (resultIvrCatAndSubCat && resultIvrCatAndSubCat.code === 200) {
                    this.setState({
                        catAndSubCatResult: get(resultIvrCatAndSubCat, 'data', []),
                        isLoadingCatSub: false,
                    })
                } else {
                    toast.error(get(resultIvrCatAndSubCat, 'data.message', ''))
                }
                await this.props.ivrWordCloudAction(ratingObj)
                let resultWordCloud = this.props.ivrReducer.ivrWordCloudRes
                if (resultWordCloud && resultWordCloud.code === 200) {
                    this.setState({
                        wordCloudResult: resultWordCloud.data,
                        isLoadingWordCloud: false,
                    })
                } else {

                    // toast.error(get(result,'data.message',''))
                }


                await this.props.ivrListAction(ratingObjData)

                let resultIvrList = this.props.ivrReducer.ivrListRes

                if (resultIvrList && resultIvrList.code === 200) {

                    get(resultIvrList, 'data', {}).finalResult.forEach(element => {
                        element.language = "0"
                    });
                    const nextPageRecord = get(resultIvrList, 'data', {}).finalResult;
                    this.setState({
                        ivrListResult: await get(resultIvrList, 'data', {}),
                        hasMore: (resultIvrList.data.currentPage <= resultIvrList.data.totalPage),
                        isLoading: false,
                        ivrListFinalResult: [
                            ...this.state.ivrListFinalResult,
                            ...nextPageRecord,
                        ],
                    })

                } else {
                    this.setState({
                        isLoading: false,
                        hasMore: false,
                    })
                    // toast.error(get(result,'data.message',''))
                }


                await this.props.ivrTotalResultAction(ratingObj)
                let resultTotal = this.props.ivrReducer.ivrTotalResults
                if (resultTotal && resultTotal.code === 200) {
                    this.setState({
                        totalResult: get(resultTotal, 'data', {}),
                        isLoadingTotalResult: false
                    })
                } else {

                    toast.error(get(resultTotal, 'message', ''))
                }

            } else {

            }
            this.getlastUpdatedData()
        })


    }

    percentageCalculation = (Obj, key) => {
        let calculateValue = ((get(Obj, key, 0)) / ((get(Obj, 'total_easy', 0)) + (get(Obj, 'total_neutral', 0)) + (get(Obj, 'total_difficult', 0))) * 100)

        calculateValue = isNaN(calculateValue) ? 0 : calculateValue
        return calculateValue.toFixed(2)
    }

    onChangeWordClick = async (e) => {
        const { name } = e.target;
        if (e.target.name === "All") {
            await this.setState({
                selectChart: [name]
            })
        } else {
            let arr = name.split(",").map((x) => {
                return parseInt(x)
            })

            await this.setState({
                selectChart: arr
            })
        }

    }

    onChangePolorClick = async (e) => {
        await this.setState({
            selectChartPolor: e.target.name
        })
    }

    handlePageChange = async (key, totalKey, value) => {
        if ([totalKey] !== 0) {
            this.setState({
                [key]: value
            })
        }

    }

    onClickDownload = async (data) => {
        console.log("e=>", data);
        let dataObj = {
            id: data._id
        }

        // await this.props.ivrDownloadAction(dataObj)

        // let result = this.props.ivrReducer.ivrDownload
        // if (result && result.code === 200) {
        //     this.setState({
        //         downloadDumpSeparateData: await get(result, 'data', []),

        //     })
        // } else {

        //     toast.error(get(result, 'message', ''))
        // }
    }

    onPlay = (index, currentPlayer) => {

        console.log(this[`player${currentPlayer || index}`].current, 'this[`player${this.state.currentPlayer}`]');
        this.setState({ currentPlayer: index })
    }

    viewModal = () => {
        this.setState({
            modal: !this.state.modal
        })
        this.getIVRCatAndSubCatResData()
    }

    onResetMtd = () => {
        this.getIvrScoreData('Reset',)
    }


    render() {
        const { ivrResult, wordCloudResult, catAndSubCatResult, countryList, ivrListResult, showAllCategory,
            ivrListFinalResult, error, agentList, plantList, ratingList, score, segmentList, startDate, endDate,
            hasMore, customerRating, cseScore, avarageCseData, agentDetail, plantDetail, countryScore,
            pageAgent, pagePlant, totalPageAgent, totalPagePlant, agentListDownload, plantListDownload,
            isLoading, isLoadingPlant, isLoadingAgent, isLoadingWordCloud, isLoadingCatSub, downloadDump, isLoadingMtdChart, isLoadingCseDialer,
            isLoadingCustomerRating, isLoadingAvgCse, isLoadingIndiaMap, isLoadingDownloadDump, isLoadingTotalResult,
            country, zone, agentName, plantName, segment, rating,
        } = this.state;


        console.log("==>", country, zone, agentName, plantName, segment, rating);

        const tabContent = (
            <Aux>
                <div className="media friendlist-box align-items-center justify-content-center m-b-20">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{ width: '40px' }} src={avatar1} alt="activity-user" /></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Silje Larsen</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-up f-22 m-r-10 text-c-green" />3784</span>
                    </div>
                </div>
                <div className="media friendlist-box align-items-center justify-content-center m-b-20">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{ width: '40px' }} src={avatar2} alt="activity-user" /></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Julie Vad</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-up f-22 m-r-10 text-c-green" />3544</span>
                    </div>
                </div>
                <div className="media friendlist-box align-items-center justify-content-center m-b-20">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{ width: '40px' }} src={avatar3} alt="activity-user" /></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Storm Hanse</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-down f-22 m-r-10 text-c-red" />2739</span>
                    </div>
                </div>
                <div className="media friendlist-box align-items-center justify-content-center m-b-20">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{ width: '40px' }} src={avatar1} alt="activity-user" /></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Frida Thomse</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-down f-22 m-r-10 text-c-red" />1032</span>
                    </div>
                </div>
                <div className="media friendlist-box align-items-center justify-content-center m-b-20">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{ width: '40px' }} src={avatar2} alt="activity-user" /></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Silje Larsen</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-up f-22 m-r-10 text-c-green" />8750</span>
                    </div>
                </div>
                <div className="media friendlist-box align-items-center justify-content-center">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{ width: '40px' }} src={avatar3} alt="activity-user" /></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Storm Hanse</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-down f-22 m-r-10 text-c-red" />8750</span>
                    </div>
                </div>
            </Aux>
        );

        return (
            <Aux>
                {/* filters */}
                <Row>
                    <Col md={2} xl={2}>
                        <Label for="exampleSelect">Select Country</Label>
                        <MultiSelect
                            options={countryList}
                            value={this.state.country}
                            onChange={(e) => this.handleChangeMultiSelect('country', e)}
                            labelledBy="Select"
                        />
                    </Col>
                    <Col md={2} xl={2}>
                        <Label for="exampleSelect">Select Zone</Label>

                        <MultiSelect
                            options={zoneList}
                            value={this.state.zone}
                            onChange={(e) => this.handleChangeMultiSelect('zone', e)}
                            labelledBy="Select"
                        />
                    </Col>
                    <Col md={2} xl={2}>
                        <Label for="exampleSelect">Agent ID</Label>
                        <MultiSelect
                            options={agentList}
                            value={this.state.agentName}
                            onChange={(e) => this.handleChangeMultiSelect('agentName', e)}
                            labelledBy="Select"
                        />
                    </Col>
                    <Col md={2} xl={2}>
                        <Label for="exampleSelect">Plant ID</Label>
                        <MultiSelect
                            options={plantList}
                            value={this.state.plantName}
                            onChange={(e) => this.handleChangeMultiSelect('plantName', e)}
                            labelledBy="Select"
                        />
                    </Col>
                    <Col md={2} xl={2}>
                        <Label for="exampleSelect">Segment</Label>
                        <MultiSelect
                            options={segmentList}
                            value={this.state.segment}
                            onChange={(e) => this.handleChangeMultiSelect('segment', e)}
                            labelledBy="Select"
                        />
                    </Col>
                    <Col md={2} xl={2}>
                        <Label for="exampleSelect">Rating</Label>
                        <MultiSelect
                            options={ratingList}
                            value={this.state.rating}
                            onChange={(e) => this.handleChangeMultiSelect('rating', e)}
                            labelledBy="Select"
                        />
                    </Col>
                    <Col md={3} xl={3}>
                        <Label for="exampleSelect">Calendar</Label>
                        <DateRangePicker DateStart={this.state.startDate} DateType={this.state.dateType} onChange={this.handleDateChange} DateEnd={this.state.endDate} />
                    </Col>
                    <Col md={3} xl={3} className="mt-3 pt-2">
                        {country.length > 0 || zone.length > 0 || agentName.length > 0 || rating.length > 0 || plantName.length > 0 || segment.length > 0 || this.state.startDate !== "" || this.state.endDate !== "" ?
                            <div>
                                <Button size="sm" className="py-2 btn-logo-color" onClick={() => this.appliedFilter("apply")} > Apply </Button>
                                {/* <Button className="py-2 btn-logo-color"    onClick={()=>this.appliedFilter("reset")}>Reset</Button> */}
                            </div> :
                            <Button className="py-2" disabled >Apply</Button>
                        }
                        {/* <Button color="primary" size="sm" className="py-2 btn-logo-color" onClick={() => this.appliedFilter("apply")} > Apply </Button> */}
                        {/* <Button color="secondary" size="sm" className="py-2 "> Reset </Button> */}
                    </Col>

                </Row>
                {/* MTD Chart */}
                <Row>
                    <Col md={12} lg={12} className="">
                        <div className="card border-card-radius">
                            <div className="div-card-bg ">
                                <h6 className=" mb-0">Feedback Responses - {score.titleText} Wise
                                    <p onClick={this.onResetMtd} name="Back" className="float-right  cursor col-lg-2"><i className="fa fa-arrow-left" aria-hidden="true" /> Back</p>
                                </h6>
                            </div>
                            <div className="card-body bg-white ">

                                <Row >
                                    <div className="col-lg-12 ">

                                        {/* <ColoumBarLineChart catAndSubCatData={catAndSubCatResult} /> */}
                                        {/* <CanvasColumnChart score={score} /> */}
                                        <NewMtdChart score={score} GetIvrScoreData={this.getIvrScoreData} Categories={score.categories} ColumnData={score.columnData} />
                                        {isLoadingMtdChart &&
                                            <div className="loaderNew">
                                                <div>
                                                    <p className="text-center" style={{ color: "#005590" }}>Loading . . .</p>
                                                    <IvrLoader />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Row>
                            </div>

                        </div>
                    </Col>
                </Row>
                {/* Customer Effort Score ( CSE ) || Customer Rating || Average Customer Effort Score ( CSE ) || Country Score Card*/}
                <Row>
                    <Col md={6} lg={6}>
                        <Row>
                            <Col md={12} lg={12}>
                                <Card className="chart-height border-card-radius">
                                    <div className="div-card-bg">
                                        <h6 className="mb-0">Customer Effort Score ( CSE )</h6>
                                        <p className="mb-0"><small>Range (-100 to 100)</small> </p>
                                    </div>
                                    {isLoadingCseDialer ?
                                        <div className="bg-white loader-height">
                                            <div className="loaderNew">
                                                <div>
                                                    <p className="text-center" style={{ color: "#005590" }}>Loading . . .</p>
                                                    <IvrLoader />
                                                </div>
                                            </div>
                                        </div> :
                                        <div>
                                            <DialerChart cseData={Math.round(get(cseScore, "ces_score", 0))} />
                                            {/* <RadialGaugeAnimation/> */}
                                            <Row className="my-5 mx-3">
                                                <Col md={4} className="text-center mb-3">
                                                    <h2 className="text-danger mt-3 f-40-emo">{get(cseScore, "ces_score", 0)}</h2>
                                                    <h6 className="text-danger f-12-emo">Customer Effort Score</h6>
                                                    <small className="text-secondary">{get(cseScore, "total_response", 0)} Responses</small>
                                                </Col>
                                                <Col md={8}>
                                                    <div className="position-relative mt-4 ">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <p className="f-13-emo">Top ({get(cseScore, "total_easy_count", 0)})</p>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="custom-success   ml-2 mt-0">

                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-success" style={{ width: `${this.percentageCalculation(cseScore, 'total_easy', 0)}${'%'}` }} >
                                                                            {`${this.percentageCalculation(cseScore, 'total_easy', 0)}${'%'}`}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div className="position-relative mt-0 ">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <p className="f-13-emo">Neutral ({get(cseScore, "total_neutral_count", 0)})</p>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className=" custom-warning">
                                                                    <div className="progress ml-2 mt-0">
                                                                        <div className="progress-bar bg-warning" style={{ width: `${this.percentageCalculation(cseScore, 'total_neutral', 0)}${'%'}` }} >
                                                                            {`${this.percentageCalculation(cseScore, 'total_neutral', 0)}${'%'}`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="position-relative mt-0 ">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <p className="f-13-emo" >Bottom ({get(cseScore, "total_difficult_count", 0)})</p>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className=" custom-progress">
                                                                    <div className="progress ml-2 mt-0">
                                                                        <div className="progress-bar bg-danger" style={{ width: `${this.percentageCalculation(cseScore, 'total_difficult', 0)}${'%'}` }} >
                                                                            {`${this.percentageCalculation(cseScore, 'total_difficult', 0)}${'%'}`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </Card>
                            </Col>
                            <Col md={12} lg={12}>
                                <Card className="chart-height-small border-card-radius">
                                    <div className="div-card-bg">
                                        <h6 className="mb-0">Customer Rating</h6>
                                        <p className="mb-0"><small>Top Scorer(4,5) | Neutral Scorer (3) | Bottom Scorer(1,2) </small></p>
                                    </div>
                                    {isLoadingCustomerRating ?
                                        <div className="bg-white loader-height">
                                            <div className="loaderNew">
                                                <div>
                                                    <p className="text-center" style={{ color: "#005590" }}>Loading . . .</p>
                                                    <IvrLoader />
                                                </div>
                                            </div>
                                        </div> :
                                        <ul className="nav my-5 card-body nav-justified">
                                            <li className="nav-item ">
                                                <div className="bck-1 text-white py-4">
                                                    <h6 className="mb-0 text-white">{get(customerRating, 'total_very_bad', 0)}</h6>
                                                </div>
                                                <p className="text-center text-dark"><small>Very Bad</small></p>
                                            </li>
                                            <li className="nav-item ">
                                                <div className="bck-2 text-white py-4">
                                                    <h6 className="mb-0 text-white">{get(customerRating, 'total_bad', 0)}</h6>
                                                </div>
                                                <p className="text-center text-dark"><small>Bad</small></p>
                                            </li>
                                            <li className="nav-item ">
                                                <div className="bck-3 text-white py-4">
                                                    <h6 className="mb-0 text-white">{get(customerRating, 'total_average', 0)}</h6>
                                                </div>
                                                <p className="text-center text-dark"><small>Average</small></p>
                                            </li>
                                            <li className="nav-item ">
                                                <div className="bck-4 text-white py-4">
                                                    <h6 className="mb-0 text-white">{get(customerRating, 'total_good', 0)}</h6>
                                                </div>
                                                <p className="text-center text-dark"><small>Good</small></p>
                                            </li>
                                            <li className="nav-item ">
                                                <div className="bck-5 text-white py-4">
                                                    <h6 className="mb-0 text-white">{get(customerRating, 'total_very_good', 0)}</h6>
                                                </div>
                                                <p className="text-center text-dark"><small>Very Good</small></p>
                                            </li>
                                        </ul>
                                    }
                                </Card></Col>
                        </Row>
                    </Col>
                    <Col md={6} lg={6}>
                        <Row>
                            <Col md={12} lg={12}> <Card className="border-card-radius chart-height-small">
                                <div className="div-card-bg">
                                    <h6 className="mb-0">Average Customer Effort Score ( CSE )</h6>
                                    <p className="mb-0"><small>Range ( 1 to 5 )</small></p>
                                </div>
                                <div className="cse-speedo-chart">
                                    {isLoadingAvgCse ?
                                        <div className="bg-white loader-height">
                                            <div className="loaderNew">
                                                <div>
                                                    <p className="text-center" style={{ color: "#005590" }}>Loading . . .</p>
                                                    <IvrLoader />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <CseSpeedo avarageCseData={avarageCseData} />
                                    }
                                </div>
                            </Card></Col>
                            <Col md={12} lg={12}>
                                <Card className="chart-height border-card-radius">
                                    <div className="div-card-bg">
                                        <h6 className="mb-0">Country Score Card </h6>
                                        <p className="mb-0"><small>Volume based chart </small></p>
                                    </div>
                                    {isLoadingIndiaMap ?
                                        <div className="bg-white loader-height">
                                            <div className="loaderNew">
                                                <div>
                                                    <p className="text-center" style={{ color: "#005590" }}>Loading . . .</p>
                                                    <IvrLoader />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="india-map">
                                            <IndiaMapChart style={{ left: "-73px ! important", height: "600px ! important" }} countryScore={countryScore} />
                                        </div>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* agentDetail List */}
                <Row>
                    <Col md={12} lg={12}>
                        {agentDetail && agentDetail.data && agentDetail.data.length == 0 ?
                            <Card className='Recent-Users border-card-radius'>
                                <Card.Header className="d-flex align-items-center justify-content-between div-card-bg">
                                    <div className="">
                                        <Card.Title as='h6' >Agent Details</Card.Title>
                                        <Card.Subtitle as='p' className="">Select Agent in-order to view agent-wise details</Card.Subtitle>
                                    </div>
                                    <h6 className="text-info">
                                        {/* <CSVLink color="black" data={agentListDownload}
                                         filename={"IVR-Agent-Data-List.csv"}
                                     >

                             <i className="fa fa-download text-info cursor mr-2" 
                              aria-hidden="true" /> 
                             </CSVLink> */}
                                        {/* Result:{agentDetail.total_data ? agentDetail.total_data : 0 }/{agentDetail.total_item ? agentDetail.total_item : 0} */}
                                    </h6>
                                </Card.Header>
                                {/* <Card.Body className='px-0 py-2'> */}


                                <h3 className="text-info table-no-data mt-3"><i className="fa fa-tasks mt-1 text-info" aria-hidden="true" />  No Data</h3>

                                {/* </Card.Body> */}
                            </Card>

                            :
                            <Card className='Recent-Users border-card-radius'>
                                <Card.Header className="d-flex align-items-center justify-content-between div-card-bg">
                                    <div className="">
                                        <Card.Title as='h6' >Agent Details</Card.Title>
                                        <Card.Subtitle as='p' className="">Select Agent in-order to view agent-wise details</Card.Subtitle>
                                    </div>
                                    <h6 className="text-info">
                                        <CSVLink color="black" data={agentListDownload}
                                            filename={"IVR-Agent-Data-List.csv"}
                                        >

                                            <i className="fa fa-download text-info cursor mr-2"
                                                aria-hidden="true" />
                                        </CSVLink>
                                        Result:{agentDetail.total_data}/{agentDetail.total_item}</h6>
                                </Card.Header>
                                {/* <Card.Body className='px-0 py-2'> */}
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Agent ID</th>
                                            <th>Agent Name</th>
                                            <th>Zone</th>
                                            <th>CSE Score</th>
                                            <th>Response Received</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agentDetail && agentDetail.data && agentDetail.data.map((agentData, agentIndex) => (
                                            <tr key={agentIndex} className="unread">
                                                <td>
                                                    <img style={{ width: '40px' }} src={avatar4} alt="activity-user" /></td>
                                                <td>
                                                    <h6 className="mb-1">{agentData.agent_id}</h6>
                                                    {/* <p className="m-0">Lorem Ipsum is simply dummy text of…</p> */}
                                                </td>
                                                <td>
                                                    <h6 className="text-muted">{agentData.agent_name}</h6>
                                                </td>
                                                <td>
                                                    <h6>{agentData.zone}</h6>
                                                </td>
                                                <td>
                                                    <h6>{agentData.ces_score}</h6>
                                                </td>
                                                <td>
                                                    <h6>{agentData.response_received}</h6>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                    {isLoadingAgent &&
                                        <div className="loaderNew">
                                            <div>
                                                <p className="text-center" style={{ color: "#005590" }}>Loading . . .</p>
                                                <IvrLoader />
                                            </div>
                                        </div>
                                    }
                                </Table>
                                <PaginationTable HandlePageChange={(e, value) => this.handlePageChange('pageAgent', 'totalPageAgent', value)} Page={pageAgent}
                                    TotalPage={totalPageAgent} />
                                {/* </Card.Body> */}
                            </Card>

                        }

                    </Col>
                </Row>
                {/* plantDetail List */}
                <Row>

                    <Col md={12} lg={12}>
                        {plantDetail && plantDetail.data && plantDetail.data.length == 0 ?
                            <Card className='Recent-Users border-card-radius'>
                                <Card.Header className="d-flex align-items-center justify-content-between div-card-bg">
                                    <div className="">
                                        <Card.Title as='h6'>Plant Details</Card.Title>
                                        <Card.Subtitle as='p' className="">List of plants associated with above agents</Card.Subtitle>
                                    </div>
                                    <h6 className="text-info">
                                        {/* <CSVLink color="black" data={plantListDownload}
                                        filename={"IVR-Plant-Data-List.csv"}
                                    > 
                            <i className="fa fa-download text-info cursor mr-2" 
                              aria-hidden="true" /> 
                              </CSVLink> */}
                                        {/* Result: {plantDetail.total_data}/{plantDetail.total_item} */}
                                    </h6>
                                </Card.Header>
                                {/* <Card.Body className='px-0 py-2'> */}
                                <h3 className="text-info table-no-data mt-3"><i className="fa fa-tasks text-info mt-1" aria-hidden="true" />  No Data</h3>

                                {/* </Card.Body> */}
                            </Card>
                            :
                            <Card className='Recent-Users border-card-radius position-relative'>
                                <Card.Header className="d-flex align-items-center justify-content-between div-card-bg">
                                    <div className="">
                                        <Card.Title as='h6'>Plant Details</Card.Title>
                                        <Card.Subtitle as='p' className="">List of plants associated with above agents</Card.Subtitle>
                                    </div>
                                    <h6 className="text-info">
                                        <CSVLink color="black" data={plantListDownload}
                                            filename={"IVR-Plant-Data-List.csv"}
                                        >
                                            <i className="fa fa-download text-info cursor mr-2"
                                                aria-hidden="true" />
                                        </CSVLink>
                                        Result: {plantDetail.total_data}/{plantDetail.total_item}</h6>
                                </Card.Header>
                                {/* <Card.Body className='px-0 py-2'> */}
                                <Table responsive hover>
                                    <thead>

                                        <tr>
                                            <th ></th>
                                            <th >Plant Id</th>
                                            <th >Plant Name</th>
                                            <th >Customer Name</th>
                                            <th >Zone</th>
                                            <th >State</th>
                                            <th >CSE Score</th>
                                            <th>Response Received</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {plantDetail && plantDetail.data && plantDetail.data.map((plantData, plantIndex) => (
                                            <tr key={plantIndex} className="unread">
                                                <td >
                                                    <img className="plant-img" src={Plant} alt="activity-user" />
                                                </td>
                                                <td >
                                                    <h6 className="mb-1">{plantData.plant_id}</h6>
                                                </td>
                                                <td >
                                                    <h6 className="mb-1">{plantData.plant_name}</h6>
                                                </td>
                                                <td >
                                                    <h6 className="mb-1">{plantData.customer_name}</h6>
                                                </td>
                                                <td >
                                                    <h6 className="text-muted">{plantData.zone}</h6>
                                                </td>
                                                <td >
                                                    <h6 className="text-muted">{plantData.state}</h6>
                                                </td>
                                                <td >
                                                    <h6>{plantData.ces_score}</h6>
                                                </td>
                                                <td>
                                                    <h6>{plantData.response_received}</h6>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                    {isLoadingPlant &&
                                        <div className="loaderNew">
                                            <div>
                                                <p className="text-center" style={{ color: "#005590" }}>Loading . . .</p>
                                                <IvrLoader />
                                            </div>
                                        </div>
                                    }
                                </Table>
                                <PaginationTable HandlePageChange={(e, value) => this.handlePageChange('pagePlant', 'totalPagePlant', value)} Page={pagePlant}
                                    TotalPage={totalPagePlant} />
                                {/* </Card.Body> */}
                            </Card>
                        }

                    </Col>
                </Row>
                {/* Dependency Graph */}
                <Row>
                    <Col md={12} lg={12} className="">
                        <div className="card-body bg-white border-card-radius div-card-bg">
                            <div className="">
                                <h6 className="mb-0">Dependency Graph</h6>
                                <p className="mb-1">IVR Dependency On Main Categories & Sub Categories</p>
                            </div>
                            <div className="row bg-white">
                                <div className="col-lg-9"><h6 className="text-center mb-0"></h6>
                                    {isLoadingCatSub ?
                                        <div className="bg-white loader-height">
                                            <div className="loaderNew">
                                                <div>
                                                    <p className="text-center" style={{ color: "#005590" }}>Loading . . .</p>
                                                    <IvrLoader />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <PolarChart CategorySubcatData={catAndSubCatResult} Name={this.state.selectChartPolor} />
                                    }
                                </div>
                                <div className="col-lg-3 border-left card-body">
                                    <p className="">
                                        Dependency Graph, This graph represents impact of predefined categories and sub-categories on the IVR CSE Score</p>
                                    <Button color="primary" onClick={(e) => { this.onChangePolorClick(e); this.setState({ showAllCategory: true }) }} name="" className={this.state.selectChartPolor == "" ? "d-block btn btn-primary main-cat-btn btn-sm btn btn-primary col-lg-8" : "d-block btn btn-sm  main1-cat-btn col-lg-8"}>All Categories</Button>
                                    {catAndSubCatResult && catAndSubCatResult.main_categories && catAndSubCatResult.main_categories.map((catData, catIndex) => {
                                        if (!showAllCategory) {
                                            if (catIndex > 3) {
                                                return (
                                                    <Button key={catIndex} color="secondary" onClick={this.onChangePolorClick} name={catData} className={this.state.selectChartPolor == catData ? "d-block btn btn-primary main-cat-btn btn-sm btn btn-primary col-lg-8" : "d-block btn btn-sm  main1-cat-btn col-lg-8"}>{catData}</Button>

                                                )
                                            } else {
                                                return null
                                            }
                                        } else {
                                            return (
                                                <Button key={catIndex} color="secondary" onClick={this.onChangePolorClick} name={catData} className={this.state.selectChartPolor == catData ? "d-block btn btn-primary main-cat-btn btn-sm btn btn-primary col-lg-8" : "d-block btn btn-sm  main1-cat-btn col-lg-8"}>{catData}</Button>

                                            )
                                        }
                                    })
                                    }
                                    {catAndSubCatResult && catAndSubCatResult.main_categories &&
                                        catAndSubCatResult.main_categories.length > 0 ?
                                        <Button onClick={this.viewModal} color="link">View All</Button> : null
                                    }


                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* Word Cloud */}
                <Row>
                    <Col md={12} lg={12} className="my-4">
                        <div className="card-body bg-white border-card-radius div-card-bg">
                            <div className="">
                                <h6 className="mb-0">Word Cloud</h6>
                                <p className="mb-1">Most Commonly Used Keywords By Customers</p>
                            </div>
                            <div className="row bg-white">
                                <div className="col-lg-9"><h6 className="text-center mb-0"></h6>
                                    {isLoadingWordCloud ?
                                        <div className="bg-white loader-height">
                                            <div className="loaderNew">
                                                <div>
                                                    <p className="text-center" style={{ color: "#005590" }}>Loading . . .</p>
                                                    <IvrLoader />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <WordCloudChart Name={this.state.selectChart} wordCloudData={get(wordCloudResult, 'ivr_english_text', '')} />

                                    }
                                </div>
                                <div className="col-lg-3 border-left card-body">
                                    <p className="">Word Cloud, This graph represents common words that customers are using during IVR feedback</p>
                                    <Button color="primary" onClick={this.onChangeWordClick} name="All" className={this.state.selectChart == "All" ? "d-block btn btn-primary main-cat-btn btn-sm btn btn-primary col-lg-8" : "d-block btn btn-primary main-cat-btn btn-sm btn btn-primary col-lg-8"}>All</Button>
                                    <Button color="success" onClick={this.onChangeWordClick} name="4,5" className={this.state.selectChart == "4,5" ? "d-block btn btn-sm  top-cat-btn col-lg-8" : "d-block btn btn-sm  top-cat-btn col-lg-8"}>Top Score(4,5)</Button>
                                    <Button color="warning" onClick={this.onChangeWordClick} name="3" className={this.state.selectChart == "3" ? "d-block btn  btn-sm  neutral-cat-btn col-lg-8" : "d-block btn  btn-sm  neutral-cat-btn col-lg-8"}>Neutral (3) </Button>
                                    <Button color="danger" onClick={this.onChangeWordClick} name="1,2" className={this.state.selectChart == "1,2" ? "d-block btn btn-sm  bottom-cat-btn col-lg-8" : "d-block btn btn-sm  bottom-cat-btn col-lg-8"}>Bottom (1,2)</Button>

                                </div>

                            </div>
                        </div>

                    </Col>
                </Row>
                {/* IVR Calls Information */}
                <Row>
                    <div className="col-lg-12">
                        <div className="bg-transparent border-0 border-card-radius mb-4">
                            <div className="">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0"><strong>IVR Calls Information</strong></h5>

                                    <div className="d-flex align-items-center">
                                        {isLoadingTotalResult &&
                                            <div className="">
                                                <div className="d-flex justify-content-between">
                                                    <p className="text-secondary mb-0 mr-3 m-3 "><small>Total Result :
                                                    </small></p>
                                                    <IvrRingLoader />
                                                </div>
                                            </div>
                                        }

                                        {isLoadingDownloadDump && isLoadingTotalResult ?
                                            <div className="">
                                                <div className="d-flex justify-content-between">


                                                    <div>
                                                        <button disabled className="btn btn-logo-color text-white m-1 btn-sm mr-0 mb-0">
                                                            <i className="fa fa-download" aria-hidden="true"></i> Download All

                                                        </button>
                                                    </div>
                                                    <IvrRingLoader />
                                                </div>
                                            </div>
                                            :

                                            <>
                                                {isLoadingTotalResult === false && <p className="text-secondary mb-0 mr-3"><small>Total Result : {this.state.totalResult.tota_result ? this.state.totalResult.tota_result : 0}</small></p>}
                                                <CSVLink color="black" data={this.state.downloadDump}
                                                    filename={"IVR-Dump-Data-List.csv"}
                                                >

                                                    <div>

                                                        <button className="btn btn-logo-color text-white  btn-sm mr-0 mb-0">
                                                            <i className="fa fa-download" aria-hidden="true"></i> Download All
                                                        </button>

                                                    </div>

                                                </CSVLink>
                                            </>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Col md={12} xl={12}>
                        <div className="">
                            {/* <div className={"loaderdiv" + ((this.state.isLoading === true) ? "" : "hidden")}>
                                <ClipLoader
                                    css={overrideLoaderCss}
                                    sizeUnit={"px"}
                                    size={60}
                                    color={loaderColorCode}
                                    loading={this.state.isLoading}
                                />
                            </div> */}
                            <Row>
                                {ivrListFinalResult.map((data, index) => (

                                    <div key={index} className="col-lg-6">
                                        <div className="card border-0 border-card-radius">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center justify-content-between mb-4">
                                                    <div className="d-flex align-items-center">
                                                        <div className="img-div"><i class="fa fa-comments"></i></div>
                                                        <div className="content-div pl-3">
                                                            <h6 className="mb-0">{data["Customer Name"] === "" ? "--" : data["Customer Name"]}</h6>
                                                            <p className="mb-0 text-secondary"><small>
                                                                {data["Response Date Time"]}
                                                            </small></p>

                                                        </div>
                                                    </div>
                                                    <div className=" d-flex justify-content-between">
                                                        <p className="ml-3 mb-0 text-secondary">
                                                            <small> ( Agent ID :   {data["Agent Id"] === null ? "--" : data["Agent Id"]} )</small><br />
                                                            <small> ( Customer Code : {data["Customer Code"] === null ? "--" : data["Customer Code"]} )</small>
                                                        </p>
                                                    </div>
                                                    <div className="">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="" id="dropdown-basic">
                                                                {/* <i className="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i> */}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu >
                                                                {/* <Dropdown.Item className="text-secondary"> <i className="fa fa-language " aria-hidden="true" onClick={(e) => this.changeLanguage(e, index)} id="0" > &nbsp;&nbsp; Translate (English)</i></Dropdown.Item>
                                                                <Dropdown.Item className="text-secondary"> <i className="fa fa-language " aria-hidden="true" onClick={(e) => this.changeLanguage(e, index)} id="1"> &nbsp;&nbsp; Translate (Hindi)</i></Dropdown.Item> */}

                                                                <CSVLink color="black" data={[data]}
                                                                    filename={"IVR-Dump-Data-List.csv"}
                                                                ><i className="fa fa-download mx-4" aria-hidden="true" >  &nbsp;&nbsp; Download </i>
                                                                </CSVLink>




                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="bg-light card-body mb-2 border-card-radius " >
                                                    <div className="custon-height-card"><p className="mb-0 content-card-new ">
                                                        {/* {data.language == '1' ? data.other_text : data.english_text} */}
                                                        {data["Feedback"]}
                                                    </p>
                                                    </div>
                                                </div>
                                                <div className=" d-flex justify-content-between">
                                                    <p className="mb-0 text-secondary"><small>
                                                        <i className="fa fa-star text-primary"></i> {data["CES Rating"]}
                                                    </small></p>
                                                    {/* <p className="ml-3 mb-0 text-secondary"><small>
                                                        <i className="fa fa-phone text-primary"></i> {data.mobile}
                                                    </small></p> */}
                                                    <p className="ml-3 mb-0 text-secondary"><small>
                                                        <i className="fa fa-flag text-primary"></i> {data.Country}
                                                    </small></p>
                                                </div>

                                                <div className="bg-light card-body mt-4 border-card-radius">
                                                    {/* {console.log("_____________________", `${data["IVR Audio"].includes("https://static-assets.knowlarity.com/playsound.html?soundurl=") ? data["IVR Audio"].replace("https://static-assets.knowlarity.com/playsound.html?soundurl=", "").trim() : data["IVR Audio"]}`)} */}
                                                    <AudioPlayer
                                                        ref={player => {
                                                            this[`player${index}`] = player;
                                                        }}
                                                        className="col-lg-12"
                                                        // src={`${data["IVR Audio"]}`}
                                                        // src={`${data["IVR Audio"]}`}
                                                        src={`${data["IVR Audio"].includes("https://static-assets.knowlarity.com/playsound.html?soundurl=") ? data["IVR Audio"].replace("https://static-assets.knowlarity.com/playsound.html?soundurl=", "").trim() : data["IVR Audio"]}`}
                                                        onPlay={e => this.onPlay(index, this.state.currentPlayer)}
                                                    // other props here
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))}



                            </Row>
                        </div>
                        {error &&
                            <li style={{ color: '#f4ae00' }}>
                                {error}
                            </li>
                        }
                        {isLoading &&
                            <div>
                                <p className="text-center" style={{ color: "#005590" }}>Loading . . .</p>
                                <IvrLoader />
                            </div>
                        }
                        {!hasMore &&
                            <li style={{ color: "#005590" }} className="text-center">No more records</li>
                        }
                    </Col>

                </Row>
                <ViewMoreModal modal={this.state.modal} OnChangePolorClick={this.onChangePolorClick} SelectChartPolor={this.state.selectChartPolor} CatAndSubCatResult={catAndSubCatResult} toggle={this.viewModal} />
            </Aux>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ivrReducer: state.ivr,
        userRes: state.users,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ivrAction, ivrWordCloudAction, ivrCatAndSubCatAction, ivrListAction,
            ivrCountryListAction, ivrTotalResultAction, ivrDownloadAction, ivrPlantListAction,
            ivrAgentListAction, ivrRatingListAction, ivrScoreAction, ivrSegmentAction, ivrCseScoreAction,
            ivrCustomerRatingAction, ivrAvarageCseAction, ivrPlantDetailAction, ivrAgentDetailAction,
            ivrCountryScoreAction, ivrAgentDetailDownloadAction, ivrPlantDetailDownloadAction, lastUpdateAction,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(windowSize(Dashboard));