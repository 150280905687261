import axios from 'axios'


export const POST = async (url, obj,setToken) => {  
   let token = await localStorage.getItem('token')
    try {
        let result = await axios.post(url, obj,{
            headers: {
                'x-auth-token': token
            }
        })
        if(result && result.data){
            return result.data
        } else {
            return null
        } 
    } catch (err) {
        console.log('ERROR in POST api' ,err)
        return err
    }     
}
export const PUT = async (url, obj,setToken) => {  
    let token = await localStorage.getItem('token')
     try {
         let result = await axios.put(url, obj,{
             headers: {
                 'x-auth-token': token
             }
         })
         if(result && result.data){
             return result.data
         } else {
             return null
         } 
     } catch (err) {
         console.log('ERROR in POST api' ,err)
         return err
     }     
 }
export const GET = async (url) => {
    try {
        let result = await axios.get(url)
        if(result && result.data){
            return result.data
        } else {
            return null
        } 
    } catch (err) {
        console.log('ERROR in GET api' ,err)
        return err
    }  
}