import { ivrScoreAndPercentConstants } from '../_constant';

let initialState = {
  loading:false,
  ivrRes:null,
  ivrWordCloudRes:null,
  ivrCatAndSubCatRes:null,
  ivrListRes:null,
  ivrTotalResults:null,
  ivrDownload:null,
  ivrCountryList:null,
  ivrAgentList:null,
  ivrPlantList:null,
  ivrRatingList:null,
  ivrScore:null,
  ivrSegmentList:null,
  ivrCseScore:null,
  ivrCustomerRating:null,
  ivrAvarageCse:null,
  ivrCountryScore:null,
  ivrPlantDetail:null,
  ivrAgentDetail:null,
  ivrAgentDetailDownload:null,
  ivrPlantDetailDownload:null,
  customerCodeList:null,
  modifyDataList:null,
  ivrAllDataList:null,
  modifyIvrDataList:null,
}

export function ivr(state = initialState, action) {
   
  switch (action.type) {
    case ivrScoreAndPercentConstants.IVR_LOADING:

      return {
        ...state,
        loading:action.payload,
      }
      break;
      case ivrScoreAndPercentConstants.IVR_SCOREANDPERCENT_SUCCESS:
        return {
          ...state,
          loading:false,
          ivrRes:action.payload
        }
        break;
        case ivrScoreAndPercentConstants.IVR_WORDCLOUD_SUCCESS:
          return {
            ...state,
            loading:false,
            ivrWordCloudRes:action.payload
          }
          break;
          case ivrScoreAndPercentConstants.IVR_CATANDSUBCAT_SUCCESS:
            return {
              ...state,
              loading:false,
              ivrCatAndSubCatRes:action.payload
            }
            break;
            case ivrScoreAndPercentConstants.IVR_LIST_SUCCESS:
            return {
              ...state,
              loading:false,
              ivrListRes:action.payload
            }
            break;
            case ivrScoreAndPercentConstants.IVR_TOTALRESULTS_SUCCESS:
              return {
                ...state,
                loading:false,
                ivrTotalResults:action.payload
              }
              break;
              case ivrScoreAndPercentConstants.IVR_DOWNLOAD_DUMP_SUCCESS:
                return {
                  ...state,
                  loading:false,
                  ivrDownload:action.payload
                }
                break;
                case ivrScoreAndPercentConstants.IVR_COUNTRY_LIST_SUCCESS:
                  return {
                    ...state,
                    loading:false,
                    ivrCountryList:action.payload
                  }
                  break;
                  case ivrScoreAndPercentConstants.IVR_AGENT_LIST_SUCCESS:
                  return {
                    ...state,
                    loading:false,
                    ivrAgentList:action.payload
                  }
                  break;
                  case ivrScoreAndPercentConstants.IVR_PLANT_LIST_SUCCESS:
                  return {
                    ...state,
                    loading:false,
                    ivrPlantList:action.payload
                  }
                  break;
                  case ivrScoreAndPercentConstants.IVR_RATING_LIST_SUCCESS:
                    return {
                      ...state,
                      loading:false,
                      ivrRatingList:action.payload
                    }
                    break;
                    case ivrScoreAndPercentConstants.IVR_SCORE_SUCCESS:
                      return {
                        ...state,
                        loading:false,
                        ivrScore:action.payload
                      }
                      break;
                      case ivrScoreAndPercentConstants.IVR_SEGMENT_SUCCESS:
                        return {
                          ...state,
                          loading:false,
                          ivrSegmentList:action.payload
                        }
                        break;
                        case ivrScoreAndPercentConstants.IVR_CSE_SCORE_SUCCESS:
                          return {
                            ...state,
                            loading:false,
                            ivrCseScore:action.payload
                          }
                          break;
                          case ivrScoreAndPercentConstants.IVR_CUSTOMER_RATING_SUCCESS:
                            return {
                              ...state,
                              loading:false,
                              ivrCustomerRating:action.payload
                            }
                            break;
                            case ivrScoreAndPercentConstants.IVR_AVARAGE_CSE_SUCCESS:
                            return {
                              ...state,
                              loading:false,
                              ivrAvarageCse:action.payload
                            }
                            break;
                            case ivrScoreAndPercentConstants.IVR_COUNTRY_SCORE_SUCCESS:
                              return {
                                ...state,
                                loading:false,
                                ivrCountryScore:action.payload
                              }
                              break;
                              case ivrScoreAndPercentConstants.IVR_AGENT_DETAIL_SUCCESS:
                                return {
                                  ...state,
                                  loading:false,
                                  ivrAgentDetail:action.payload
                                }
                                break;
                                case ivrScoreAndPercentConstants.IVR_PLANT_DETAIL_SUCCESS:
                                  return {
                                    ...state,
                                    loading:false,
                                    ivrPlantDetail:action.payload
                                  }
                                  break;
                                  case ivrScoreAndPercentConstants.IVR_PLANT_DETAIL_DOWNLOAD_SUCCESS:
                                    return {
                                      ...state,
                                      loading:false,
                                      ivrPlantDetailDownload:action.payload
                                    }
                                    break;
                                    case ivrScoreAndPercentConstants.IVR_AGENT_DETAIL_DOWNLOAD_SUCCESS:
                                      return {
                                        ...state,
                                        loading:false,
                                        ivrAgentDetailDownload:action.payload
                                      }
                                      break;
                                      case ivrScoreAndPercentConstants.IVR_CUSTOMER_CODE_SUCCESS:
                                        return {
                                          ...state,
                                          loading:false,
                                          customerCodeList:action.payload
                                        }
                                        break;
                                        case ivrScoreAndPercentConstants.IVR_ALL_DATA_LIST_SUCCESS:
                                          return {
                                            ...state,
                                            loading:false,
                                            ivrAllDataList:action.payload
                                          }
                                          break;
                                          case ivrScoreAndPercentConstants.IVR_MODIFY_DATA_LIST_SUCCESS:
                                            return {
                                              ...state,
                                              loading:false,
                                              modifyDataList:action.payload
                                            }
                                            break;
                                            case ivrScoreAndPercentConstants.IVR_MODIFY_IVR_DATA_LIST_SUCCESS:
                                              return {
                                                ...state,
                                                loading:false,
                                                modifyIvrDataList:action.payload
                                              }
                                              break;
                                              case ivrScoreAndPercentConstants.IVR_MODIFY_IVR_UPDATE_DATA_LIST_SUCCESS:
                                                return {
                                                  ...state,
                                                  loading:false,
                                                  modifyIvrUpdateDataList:action.payload
                                                }
                                                break;
    default:
      return state
  }
}

