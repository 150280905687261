import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Exporting from 'highcharts/modules/exporting';
import ExportData from "highcharts/modules/export-data.js"
import highchartsMore from "highcharts/highcharts-more.js"





Exporting(Highcharts);
ExportData(Highcharts);
highchartsMore(Highcharts);


const PolarChart = (props) => {
 const {Name,CategorySubcatData} = props;
 
CategorySubcatData && CategorySubcatData.main_categories && CategorySubcatData.main_categories.map((data,index)=>{
   return  data = data +'</span></span>'
})

const options = {
    colors: ['#DF5353','#DDDF0D','#55BF3B'],
    chart: {
        type: 'column',
        inverted: true,
        polar: true
    },
    title: {
        text: ''
    },
    tooltip: {
        outside: true
    },
    pane: {
        size: '85%',
        innerSize: '20%',
        endAngle: 270
    },
    
    xAxis: {
        tickInterval: 1,
        labels: {
            align: 'right',
            useHTML: true,
            allowOverlap: true,
            step: 1,
            y: 3,
            style: {
                fontSize: '13px'
            }
        },
        lineWidth: 0,
        categories: CategorySubcatData.main_categories
        // [
        //     'Main Category 1 ' +
        //     '</span></span>',
        //     'Main Category 2 ' +
        //     '</span></span>',
        //     'Main Category 3 ' +
        //     '</span></span>',
            
        // ]
    },
    credits: {
        enabled: false
      },
      legend: {
        enabled: false
     },
      exporting: {
        enabled: false
      },
    yAxis: {
        crosshair: {
            enabled: true,
            color: '#333'
        },
        lineWidth: 0,
        tickInterval: 25,
        reversedStacks: false,
        endOnTick: true,
        showLastLabel: true
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            borderWidth: 0,
            pointPadding: 0,
            groupPadding: 0.15
        }
    },
    series: CategorySubcatData.sub_categories
    // [{
    //     name: 'Sub-Category 1',
    //     data: [132, 105, 92, ]
    // }, {
    //     name: 'Sub-Category 2',
    //     data: [125, 110, 86, ]
    // }, {
    //     name: 'Sub-Category 3',
    //     data: [111, 90, 60, ]
    // }]
    }



    return ( 
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            
        />
     );
}
 
export default PolarChart;