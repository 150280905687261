import { userConstants } from '../_constant';

let initialState = {
  loading:false,
  loginRes:null,
  registerRes:null,
  token:null,
  lastUpdateRes:null,
  settingAccessibleRes:null,
  isAccessable:false,
  ivrRecordingAccess:false,
  ivrNavBarListRes:null,
}

export function users(state = initialState, action) {
   
  switch (action.type) {
    case userConstants.AUTH_REQUEST:
      return {
        ...state,
        loading:action.payload,
      }
      break;
      case userConstants.LOGIN_SUCCESS:
        return {
          ...state,
          loading:false,
          loginRes:action.payload
        }
        break;
        case userConstants.SET_TOKEN:
        return {
          ...state,
          token:action.payload
        }
        break;
        case userConstants.REGISTER_SUCCESS:
        return {
          ...state,
          loading:false,
          registerRes:action.payload
        }
        break;
        case userConstants.LAST_UPDATE_SUCCESS:
          return {
            ...state,
            loading:false,
            lastUpdateRes:action.payload
          }
          break;
          case userConstants.SETTING_ACCESSIBLE_SUCCESS:
            return {
              ...state,
              loading:false,
              settingAccessibleRes:action.payload
            }
            break;
    
          case userConstants.SET_ACCESSIBLE:
            return {
              ...state,
              loading:false,
              isAccessable:action.payload==="false" ? false : true
            }
            break;
            case userConstants.SET_IVR_RECORDING_ACCESSIBLE:
            return {
              ...state,
              loading:false,
              ivrRecordingAccess:action.payload==="false" ? false : true
            }
            break;
            case userConstants.SETTING_ACCESSIBLE_TAB_SUCCESS:
              return {
                ...state,
                loading:false,
                ivrNavBarListRes:action.payload
              }
              break;
    default:
      return state
  }
}