import { config,messages } from '../_config';
import { ivrScoreAndPercentConstants } from '../_constant';
import {GET, POST,PUT} from './response';

export const ivrAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrScoreAndPercent,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_SCOREANDPERCENT_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_SCOREANDPERCENT_SUCCESS,payload:messages.catchErr})
    }
}

export const ivrWordCloudAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrwordCloud,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_WORDCLOUD_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_WORDCLOUD_SUCCESS,payload:messages.catchErr})
    }
}

export const ivrCatAndSubCatAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrCatAndSubCat,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_CATANDSUBCAT_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_CATANDSUBCAT_SUCCESS,payload:messages.catchErr})
    }
}

export const ivrListAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_LIST_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_LIST_SUCCESS,payload:messages.catchErr})
    }
}

export const ivrTotalResultAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrTotalResult,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_TOTALRESULTS_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_TOTALRESULTS_SUCCESS,payload:messages.catchErr})
    }
}

export const ivrDownloadAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrDownload,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_DOWNLOAD_DUMP_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_DOWNLOAD_DUMP_SUCCESS,payload:messages.catchErr})
    }
}
export const ivrCountryListAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrCountryList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_COUNTRY_LIST_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_COUNTRY_LIST_SUCCESS,payload:messages.catchErr})
    }
}
export const ivrAgentListAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrAgentList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_AGENT_LIST_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_AGENT_LIST_SUCCESS,payload:messages.catchErr})
    }
}

export const ivrPlantListAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrPlantList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_PLANT_LIST_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_PLANT_LIST_SUCCESS,payload:messages.catchErr})
    }
}
export const ivrRatingListAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrRatingList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_RATING_LIST_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_RATING_LIST_SUCCESS,payload:messages.catchErr})
    }
}

export const ivrScoreAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrScoreList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_SCORE_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_SCORE_SUCCESS,payload:messages.catchErr})
    }
}

export const ivrSegmentAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrSegmentList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_SEGMENT_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_SEGMENT_SUCCESS,payload:messages.catchErr})
    }
}

export const ivrCseScoreAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrCseScore,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_CSE_SCORE_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_CSE_SCORE_SUCCESS,payload:messages.catchErr})
    }
}

export const ivrCustomerRatingAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrCustomerRating,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_CUSTOMER_RATING_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_CUSTOMER_RATING_SUCCESS,payload:messages.catchErr})
    }
}
export const ivrAvarageCseAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrAvarageCse,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_AVARAGE_CSE_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_AVARAGE_CSE_SUCCESS,payload:messages.catchErr})
    }
}
export const ivrCountryScoreAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrCountryScore,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_COUNTRY_SCORE_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_COUNTRY_SCORE_SUCCESS,payload:messages.catchErr})
    }
}
export const ivrAgentDetailAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrAgentDetail,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_AGENT_DETAIL_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_AGENT_DETAIL_SUCCESS,payload:messages.catchErr})
    }
}
export const ivrPlantDetailAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrPlantDetail,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_PLANT_DETAIL_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_PLANT_DETAIL_SUCCESS,payload:messages.catchErr})
    }
}
export const ivrAgentDetailDownloadAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrAgentDetailDownload,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_AGENT_DETAIL_DOWNLOAD_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_AGENT_DETAIL_DOWNLOAD_SUCCESS,payload:messages.catchErr})
    }
}
export const ivrPlantDetailDownloadAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrPlantDetailDownload,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_PLANT_DETAIL_DOWNLOAD_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_PLANT_DETAIL_DOWNLOAD_SUCCESS,payload:messages.catchErr})
    }
}
export const customerRatingAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrCustomerRating,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_CUSTOMER_RATING_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_CUSTOMER_RATING_SUCCESS,payload:messages.catchErr})
    }
}
export const customerCodeListAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await GET(config.customerCodeList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_CUSTOMER_CODE_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_CUSTOMER_CODE_SUCCESS,payload:messages.catchErr})
    }
}
export const modifyDataListAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.modifyDataList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_MODIFY_DATA_LIST_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_MODIFY_DATA_LIST_SUCCESS,payload:messages.catchErr})
    }
}
export const ivrAllDataListAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST(config.ivrAllDataList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_ALL_DATA_LIST_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_ALL_DATA_LIST_SUCCESS,payload:messages.catchErr})
    }
}

export const modifyDataListUpdateAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await PUT (config.modifyDataList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_MODIFY_DATA_LIST_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_MODIFY_DATA_LIST_SUCCESS,payload:messages.catchErr})
    }
}

export const modifyDataIvrListUpdateAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await POST (config.modifyIvrDataList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_MODIFY_IVR_DATA_LIST_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_MODIFY_IVR_DATA_LIST_SUCCESS,payload:messages.catchErr})
    }
}
export const modifyDataIvrUpdateListUpdateAction = (data) => async (dispatch) => {
    dispatch({type:ivrScoreAndPercentConstants.IVR_LOADING, payload: true})
    try {
        let response = await PUT (config.modifyIvrUpdateDataList,data)
        return await dispatch({
            type: ivrScoreAndPercentConstants.IVR_MODIFY_IVR_UPDATE_DATA_LIST_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:ivrScoreAndPercentConstants.IVR_MODIFY_IVR_UPDATE_DATA_LIST_SUCCESS,payload:messages.catchErr})
    }
}