import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ViewMoreModal = (props) => {
    
    const {modal ,toggle,CatAndSubCatResult,OnChangePolorClick,SelectChartPolor} = props;
  const {
    buttonLabel,
    className
  } = props;

//   const [modal, setModal] = useState(false);

//   const toggle = () => setModal(!modal);


    return ( 
        <div>
        {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>All Categories</ModalHeader>
          <ModalBody>

          {CatAndSubCatResult && CatAndSubCatResult.main_categories && CatAndSubCatResult.main_categories.map((catData,catIndex)=>(
                                           <Button key={catIndex} color="secondary" onClick={OnChangePolorClick} toggle={toggle} name={catData} className={SelectChartPolor == catData ? "d-block btn btn-primary main-cat-btn btn-sm btn btn-primary col-lg-8" : "d-block btn btn-sm  main1-cat-btn col-lg-8"}>{catData}</Button>
                              
                                   )) }    
                                                
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
     );
}
 
export default ViewMoreModal;