import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import WordCloud from "highcharts/modules/wordcloud.src.js"
import Exporting from "highcharts/modules/exporting.js"
import ExportData from "highcharts/modules/export-data.js"
import { get } from 'lodash'


ExportData(Highcharts);
Exporting(Highcharts);
WordCloud(Highcharts);

const ColoumBarLineChart = (props) => {
  const { Name,wordCloudData } = props
  const [open, setOpen] = useState(false);


  // WordCloudChart //
  const [wordCloudChartResultData, setWordCloudChartResultData] = useState('')
 

  // commentDetail //
  const [commentDetailResultData, setCommentDetailResultData] = useState([])

//   useEffect(() => {
//     wordCloudChart()
//   }, [])

//   useEffect(() => {
//     wordCloudChart()
//   }, [Name])

//   const wordCloudChart = async () => {
//     let wordCloudChart = await api.get(config.dashboardWordCloud, Name === 'All' ? false : true, { type: Name });
//     let wordCloudChartResultData = get(wordCloudChart, 'data', '')
//     setWordCloudChartResultData(wordCloudChartResultData)
//   }

//   const commentDetail = async (comment, type) => {
//     let commentDetail = await api.get(config.dashboardCommentDetail, true, { comment, type });
//     let commentDetailResultData = get(commentDetail, 'data', [])
//     setCommentDetailResultData(commentDetailResultData)
//     if (commentDetailResultData.length > 0) {
//       setOpen(!open)
//     }
//   }

//   const handleModel = async (e) => {
//     const { name } = get(e, 'point', 'options', {})
//     let comment = name
//     let type = Name
//     await commentDetail(comment, type)

//   }

  const options = {
    title: {
      text: ''
  },
  xAxis: {
      categories: ['Jan','Feb', 'Mar','APR', 'May','Jun', 'Jul','Aug', 'Sep','Oct', 'Nov','Dec']
  },
  yAxis:{
      title:""
  },
  labels: {
      items: [{
          // html: 'Total fruit consumption',
          style: {
              left: '50px',
              top: '18px',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'black'
          }
      }]
  },
  exporting: {
    enabled: false
},
  credits: {
    enabled: false
  },
  series: [{
      type: 'column',
      name: 'Call Transferred',
      data: [500,1000,1500,2000,2500,5000,200,0,0,0,0,0]
  },{
      type: 'spline',
      name: 'Response Received',
      data: [750,1200,1750, 2000,2200,2500],
      marker: {
          lineWidth: 2,
          lineColor: 'red',
          fillColor: 'white'
      }
  }]
  }


  return (
    <Fragment>

      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      {/* <div style={{ display: "none" }}><CommentModel handleModel={() => setOpen(!open)} commentDetailResultData={commentDetailResultData} open={open} />
      </div> */}
    </Fragment>
  );
}

export default ColoumBarLineChart;