import { config,messages } from '../_config';
import { userConstants } from '../_constant';
import {GET, POST} from './response';

export const loginAction = (data) => async (dispatch) => {
    dispatch({type:userConstants.AUTH_REQUEST, payload: true})
    try {
        let response = await POST(config.login,data)
        return await dispatch({
            type: userConstants.LOGIN_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:userConstants.LOGIN_SUCCESS,payload:messages.catchErr})
    }
}

export const setToken = (token) => async (dispatch) => {
    dispatch({type:userConstants.SET_TOKEN, payload: token|| null})
}
export const setIsAccessable = (isAccessable) => async (dispatch) => {
    dispatch({type:userConstants.SET_ACCESSIBLE, payload: isAccessable|| "false"})
}
export const setIvrRecordingAccess = (ivrRecordingAccess) => async (dispatch) => {
    dispatch({type:userConstants.SET_IVR_RECORDING_ACCESSIBLE, payload: ivrRecordingAccess|| "false"})
}
export const lastUpdateAction = (data) => async (dispatch) => {
    dispatch({type:userConstants.LAST_UPDATE_LOADING, payload: true})
    try {
        let response = await POST(config.lastUpdateResult,data)
        return await dispatch({
            type: userConstants.LAST_UPDATE_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in last update action', err)
        dispatch({type:userConstants.LAST_UPDATE_SUCCESS,payload:messages.catchErr})
    }
}

export const settingAction = (data) => async (dispatch) => {
    dispatch({type:userConstants.AUTH_REQUEST, payload: true})
    try {
        let response = await POST(config.settingAccessible,data)
        return await dispatch({
            type: userConstants.SETTING_ACCESSIBLE_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:userConstants.SETTING_ACCESSIBLE_SUCCESS,payload:messages.catchErr})
    }
}

export const accessibleTabsAction = (data) => async (dispatch) => {
    dispatch({type:userConstants.AUTH_REQUEST, payload: true})
    try {
        let response = await POST(config.accessibleTabsAccessible,data)
        return await dispatch({
            type: userConstants.SETTING_ACCESSIBLE_TAB_SUCCESS,payload: response
        });  
    } catch (err) {
        console.log('ERROR in login action', err)
        dispatch({type:userConstants.SETTING_ACCESSIBLE_TAB_SUCCESS,payload:messages.catchErr})
    }
}