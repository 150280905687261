import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import exporting from 'highcharts/modules/exporting';





exporting(Highcharts);


const PieChart = (props) => {
 
 
 


const options = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    height:'387px'
},
title: {
    text: ''
},
tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
},
accessibility: {
    point: {
        valueSuffix: '%'
    }
},
plotOptions: {
    pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
    }
}, 
credits: {
  enabled: false
},
series: [{
    name: 'Brands',
    colorByPoint: true,
    data: [{
        name: 'Excellent',
        y: 66.1,
        sliced: true,
        selected: true
    }, {
        name: 'Very Good',
        y: 12.7
    }, {
        name: 'Good',
        y: 11.7
    }, {
        name: 'Poor',
        y: 5.0
    }, {
        name: 'Very Poor',
        y: 4.5
    }
  ]
}]
    }



    return ( 
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            
        />
     );
}
 
export default PieChart;