import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Sankey from "highcharts/modules/sankey.js"
import { get } from 'lodash'

Sankey(Highcharts);

const CategorySubcat2 = (props) => {
  const { Name } = props
  const [open, setOpen] = useState(false);


  // WordCloudChart //
  const [wordCloudChartResultData, setWordCloudChartResultData] = useState('')
 

  // commentDetail //
  const [commentDetailResultData, setCommentDetailResultData] = useState([])

//   useEffect(() => {
//     wordCloudChart()
//   }, [])

//   useEffect(() => {
//     wordCloudChart()
//   }, [Name])

//   const wordCloudChart = async () => {
//     let wordCloudChart = await api.get(config.dashboardWordCloud, Name === 'All' ? false : true, { type: Name });
//     let wordCloudChartResultData = get(wordCloudChart, 'data', '')
//     setWordCloudChartResultData(wordCloudChartResultData)
//   }

//   const commentDetail = async (comment, type) => {
//     let commentDetail = await api.get(config.dashboardCommentDetail, true, { comment, type });
//     let commentDetailResultData = get(commentDetail, 'data', [])
//     setCommentDetailResultData(commentDetailResultData)
//     if (commentDetailResultData.length > 0) {
//       setOpen(!open)
//     }
//   }

//   const handleModel = async (e) => {
//     const { name } = get(e, 'point', 'options', {})
//     let comment = name
//     let type = Name
//     await commentDetail(comment, type)

//   }

  const options = {
   
title: {
  text: ''
},

accessibility: {
  point: {
      valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
  }
},

credits: {
  enabled: false
},
series: [{
  keys: ['from', 'to', 'weight'],
  data: [
   
    ['WEBFORM', 'Application', 12],
    ['WEBFORM', 'Support', 20],
    ['WEBFORM', 'Service', 23],
    ['WEBFORM', 'Transaction', 13],
    ['WEBFORM', 'Suggestion', 32],
    ['WEBFORM', 'Genric', 13],
    ['WEBFORM', 'IPO', 23],

    ['Application', 'Experience', 21],
    ['Application', 'Registration', 30],
    ['Application', 'Technical Error', 14],
    ['IPO', 'Ipo Refund', 23],
    ['Support', 'Resolution', 16],
    ['Support', 'Response', 20],
    ['Support', 'Behaviour', 10],
    ['Suggestion', 'Registration', 10],
    ['Suggestion', 'Behaviour', 14],
    ['Service', 'Payment Status', 19],
    ['Service', 'Behaviour', 14],
    ['Transaction', 'Payment Status', 19],
    ['Transaction', 'Pending', 17],
    ['Transaction', 'Refund', 23],
    ['Genric', 'Statement', 26],
   
  ],
  type: 'sankey',
  name: ''
}]

  
  }


  return (
    <Fragment>

      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      {/* <div style={{ display: "none" }}><CommentModel handleModel={() => setOpen(!open)} commentDetailResultData={commentDetailResultData} open={open} />
      </div> */}
    </Fragment>
  );
}

export default CategorySubcat2;