export const ivrScoreAndPercentConstants = {
    IVR_LOADING:'IVR_LOADING',
    IVR_SCOREANDPERCENT_SUCCESS: 'IVR_SCOREANDPERCENT_SUCCESS',
    IVR_WORDCLOUD_SUCCESS:'IVR_WORDCLOUD_SUCCESS',
    IVR_CATANDSUBCAT_SUCCESS:'IVR_CATANDSUBCAT_SUCCESS',
    IVR_LIST_SUCCESS:'IVR_LIST_SUCCESS',
    IVR_TOTALRESULTS_SUCCESS:'IVR_TOTALRESULTS_SUCCESS',
    IVR_DOWNLOAD_DUMP_SUCCESS:'IVR_DOWNLOAD_DUMP_SUCCESS',
    IVR_COUNTRY_LIST_SUCCESS:'IVR_COUNTRY_LIST_SUCCESS',
    IVR_AGENT_LIST_SUCCESS:'IVR_AGENT_LIST_SUCCESS',
    IVR_PLANT_LIST_SUCCESS:'IVR_PLANT_LIST_SUCCESS',
    IVR_RATING_LIST_SUCCESS:'IVR_RATING_LIST_SUCCESS',
    IVR_SCORE_SUCCESS:'IVR_SCORE_SUCCESS',
    IVR_SEGMENT_SUCCESS:'IVR_SEGMENT_SUCCESS',
    IVR_CSE_SCORE_SUCCESS:'IVR_CSE_SCORE_SUCCESS',
    IVR_CUSTOMER_RATING_SUCCESS:'IVR_CUSTOMER_RATING_SUCCESS',
    IVR_AVARAGE_CSE_SUCCESS:'IVR_AVARAGE_CSE_SUCCESS',
    IVR_COUNTRY_SCORE_SUCCESS:'IVR_COUNTRY_SCORE_SUCCESS',
    IVR_AGENT_DETAIL_SUCCESS:'IVR_AGENT_DETAIL_SUCCESS',
    IVR_PLANT_DETAIL_SUCCESS:'IVR_PLANT_DETAIL_SUCCESS',
    IVR_PLANT_DETAIL_DOWNLOAD_SUCCESS:'IVR_PLANT_DETAIL_DOWNLOAD_SUCCESS',
    IVR_AGENT_DETAIL_DOWNLOAD_SUCCESS:'IVR_AGENT_DETAIL_DOWNLOAD_SUCCESS',
    IVR_CUSTOMER_CODE_SUCCESS:'IVR_CUSTOMER_CODE_SUCCESS',
    IVR_ALL_DATA_LIST_SUCCESS:'IVR_ALL_DATA_LIST_SUCCESS',
    IVR_MODIFY_DATA_LIST_SUCCESS:'IVR_MODIFY_DATA_LIST_SUCCESS',
    IVR_MODIFY_IVR_DATA_LIST_SUCCESS:'IVR_MODIFY_IVR_DATA_LIST_SUCCESS',
    IVR_MODIFY_IVR_UPDATE_DATA_LIST_SUCCESS:'IVR_MODIFY_IVR_UPDATE_DATA_LIST_SUCCESS',
};