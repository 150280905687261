import React, { useState, useEffect } from 'react';
import { css } from "@emotion/react";
import {CircleLoader} from "react-spinners";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #005590;
  color:"#005590";
`;

const IvrRingLoader = (props) => {

    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#005590");


    return ( 
        <div className="sweet-loading m-1 mt-3">
        <CircleLoader speedMultiplier={1.3} css={override} color={"#005590"} height={10} loading={loading} size={30} />
      </div>
     );
}
 
export default IvrRingLoader;