import React from 'react';
import {Row, Col, Card, Table, Tabs, Tab} from 'react-bootstrap';

import Aux from "../../hoc/_Aux";
import DEMO from "../../store/_constant/constant";

import avatar1 from '../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../assets/images/user/avatar-2.jpg';
import avatar3 from '../../assets/images/user/avatar-3.jpg';
import WordCloudChart from '../../common/charts/wordCloud';
import PieChart from '../../common/charts/pieChart';
import CategorySubcat1 from '../../common/charts/categorySubcat1';

class Dashboard extends React.Component {
    render() {
        const tabContent = (
            <Aux>
                <div className="media friendlist-box align-items-center justify-content-center m-b-20">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{width: '40px'}} src={avatar1} alt="activity-user"/></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Silje Larsen</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-up f-22 m-r-10 text-c-green"/>3784</span>
                    </div>
                </div>
                <div className="media friendlist-box align-items-center justify-content-center m-b-20">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{width: '40px'}} src={avatar2} alt="activity-user"/></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Julie Vad</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-up f-22 m-r-10 text-c-green"/>3544</span>
                    </div>
                </div>
                <div className="media friendlist-box align-items-center justify-content-center m-b-20">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{width: '40px'}} src={avatar3} alt="activity-user"/></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Storm Hanse</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-down f-22 m-r-10 text-c-red"/>2739</span>
                    </div>
                </div>
                <div className="media friendlist-box align-items-center justify-content-center m-b-20">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{width: '40px'}} src={avatar1} alt="activity-user"/></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Frida Thomse</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-down f-22 m-r-10 text-c-red"/>1032</span>
                    </div>
                </div>
                <div className="media friendlist-box align-items-center justify-content-center m-b-20">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{width: '40px'}} src={avatar2} alt="activity-user"/></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Silje Larsen</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-up f-22 m-r-10 text-c-green"/>8750</span>
                    </div>
                </div>
                <div className="media friendlist-box align-items-center justify-content-center">
                    <div className="m-r-10 photo-table">
                        <a href={DEMO.BLANK_LINK}><img className="rounded-circle" style={{width: '40px'}} src={avatar3} alt="activity-user"/></a>
                    </div>
                    <div className="media-body">
                        <h6 className="m-0 d-inline">Storm Hanse</h6>
                        <span className="float-right d-flex  align-items-center"><i className="fa fa-caret-down f-22 m-r-10 text-c-red"/>8750</span>
                    </div>
                </div>
            </Aux>
        );

        return (
            <Aux>
                <Row>
                <Col md={6} xl={4}>
                        <Card>
                            <Card.Body>
                                <h6 className='mb-4'>CES Score</h6>
                                <div className="row d-flex align-items-center">
                                    <div className="col-9">
                                        <h3 className="f-w-300 d-flex align-items-center m-b-0"><i className="feather icon-arrow-up text-danger-custom f-30 m-r-5" /> 90</h3>
                                    </div>

                                    <div className="col-3 text-right">
                                        <p className="m-b-0"></p>
                                    </div>
                                </div>
                                <div className="progress m-t-30" style={{ height: '7px' }}>
                                    <div className="progress-bar progress-c-theme" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} xl={4}>
                        <Card>
                            <Card.Body>
                                <h6 className='mb-4'>Total Call Transferred</h6>
                                <div className="row d-flex align-items-center">
                                    <div className="col-9">
                                        <h3 className="f-w-300 d-flex align-items-center m-b-0"><i className="feather icon-arrow-up text-danger-custom f-30 m-r-5" /> 4000</h3>
                                    </div>

                                    <div className="col-3 text-right">
                                        <p className="m-b-0">50%</p>
                                    </div>
                                </div>
                                <div className="progress m-t-30" style={{ height: '7px' }}>
                                    <div className="progress-bar progress-c-theme" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} xl={4}>
                        <Card>
                            <Card.Body>
                                <h6 className='mb-4'>Total Response Recieved</h6>
                                <div className="row d-flex align-items-center">
                                    <div className="col-9">
                                        <h3 className="f-w-300 d-flex align-items-center m-b-0"><i className="feather icon-arrow-up text-danger-custom f-30 m-r-5" /> 3000</h3>
                                    </div>

                                    <div className="col-3 text-right">
                                        <p className="m-b-0">75%</p>
                                    </div>
                                </div>
                                <div className="progress m-t-30" style={{ height: '7px' }}>
                                    <div className="progress-bar progress-c-theme" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} xl={4}>
                        <Card>
                            <Card.Body>
                                <h6 className='mb-4'>Top Score (4 , 5)</h6>
                                <div className="row d-flex align-items-center">
                                    <div className="col-9">
                                        <h3 className="f-w-300 d-flex align-items-center m-b-0"><i className="feather icon-arrow-up text-danger-custom f-30 m-r-5" /> 2000</h3>
                                    </div>

                                    <div className="col-3 text-right">
                                        <p className="m-b-0">66.7</p>
                                    </div>
                                </div>
                                <div className="progress m-t-30" style={{ height: '7px' }}>
                                    <div className="progress-bar progress-c-theme" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} xl={4}>
                        <Card>
                            <Card.Body>
                                <h6 className='mb-4'>Neutral Response(3)</h6>
                                <div className="row d-flex align-items-center">
                                    <div className="col-9">
                                        <h3 className="f-w-300 d-flex align-items-center m-b-0"><i className="feather icon-arrow-up text-danger-custom f-30 m-r-5" /> 500</h3>
                                    </div>

                                    <div className="col-3 text-right">
                                        <p className="m-b-0">16.6%</p>
                                    </div>
                                </div>
                                <div className="progress m-t-30" style={{ height: '7px' }}>
                                    <div className="progress-bar progress-c-theme" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} xl={4}>
                        <Card>
                            <Card.Body>
                                <h6 className='mb-4'>Bottom Score(1,2)</h6>
                                <div className="row d-flex align-items-center">
                                    <div className="col-9">
                                        <h3 className="f-w-300 d-flex align-items-center m-b-0"><i className="feather icon-arrow-up text-danger-custom f-30 m-r-5" /> 500</h3>
                                    </div>

                                    <div className="col-3 text-right">
                                        <p className="m-b-0">16.6%</p>
                                    </div>
                                </div>
                                <div className="progress m-t-30" style={{ height: '7px' }}>
                                    <div className="progress-bar progress-c-theme" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                   
                     
                    <div className="col-lg-12">
                        <div className="bg-transparent border-0 border-card-radius mb-4">
                            <div className="">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0"><strong>Questions & Answers Analysis</strong></h5>
                                    {/* <div className="d-flex align-items-center">
                                        <p className="text-secondary mb-0 mr-3"><small>Total Result : 12,476</small></p>
                                        <button className="btn btn-success btn-sm mr-0 mb-0"><i className="fa fa-filter" aria-hidden="true"></i>FILTER </button>
                                    </div> */}
                                </div>                            
                            </div>
                        </div>



                       
                    </div>
                </Row>
                <Row>
                
                    <Col  md={12} xl={12}>
                        <div className="card card-body border-0 border-card-radius ">
                            
                        <Row>
                        <div className="col-lg-12">
                            <div className="alert alert-info">
                                <strong>Question Asked: </strong>  How was your overall experience while interacting with our customer support executive ?
                            </div>
                        </div>
                        <Col md={6} xl={6}>
                        <div className=" card-body-min-height border-0 mb-0">
                    <Table className="mb-0" striped bordered hover>
                        <thead>
                            <tr>
                            <th>Options in Survey</th>
                            <th>No. of Hits on Options</th>
                            <th>Hits Percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>Very Poor</td>
                            <td>500</td>
                            <td>30 %</td>
                            </tr>
                            <tr>
                            <td>Poor</td>
                            <td>1,000</td>
                            <td>25 %</td>
                            </tr>
                            <tr>
                            <td>Good</td>
                            <td>500</td>
                            <td>10 %</td>
                            </tr>
                            <tr>
                            <td>Very Good</td>
                            <td>2,000</td>
                            <td>25 %</td>
                            </tr>
                            <tr>
                            <td>Excellent</td>
                            <td>4,000</td>
                            <td>10 %</td>
                            </tr>
                        </tbody>
                    </Table>
                    </div>
                    </Col>
                    <Col  md={6} xl={6}>
                        <div className="border-card-radius card-body bg-white">
                            <PieChart className=""/>
                        </div>
                    </Col>
                        </Row>
                        </div>
                    </Col>
                    
                    <div className=" col-lg-12 my-4">
                        <div className="card-body bg-white border-card-radius">
                            <div className="row">
                                <div className="col-lg-10"><h6 className="text-center mb-0"></h6>
                                <CategorySubcat1/>
                                </div>
                                <div className="col-lg-2 border-left card-body"><p className="mb-0">Dependency Graph, This graph represents impact of predefined categories and sub-categories on the Agent feedback CES Score.</p></div>
                            </div>
                        </div>
                    </div>
                    <div className=" col-lg-12 my-4">
                        <div className="card-body bg-white border-card-radius">
                            <div className="row">
                                <div className="col-lg-10"><h6 className="text-center mb-0"></h6>
                                <WordCloudChart/>
                                </div>
                                <div className="col-lg-2 border-left card-body"><p className="mb-0">Word Cloud, This graph represents common words that customers are using during Agent feedback</p></div>
                            </div>
                        </div>
                    </div>
                    
                </Row>
                
            </Aux>
        );
    }
}

export default Dashboard;