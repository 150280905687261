import React, { Component, Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';
import { setToken,setIsAccessable,setIvrRecordingAccess } from '../store/_action/userAuth.action'
import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import { withCookies } from 'react-cookie';
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import SignIn1 from '../Demo/Authentication/SignIn/SignIn1';
import SignUp1 from '../Demo/Authentication/SignUp/SignUp1';

import { toast } from 'react-toastify';
import windowSize from 'react-window-size';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PrivateRoute } from './privateRoutes';
// import DashboardDefaults from '../Demo/Dashboard/Defaults'
// import DashboardIvr from '../Demo/Dashboard/Ivr'
// import DashboardWebform from '../Demo/Dashboard/Webform'
// import DashboardAgentFeedback from '../Demo/Dashboard/AgentFeedback'

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

const App = (props) => {
    const { cookies, token } = props;

    useEffect(() => {
        let userToken = cookies.get("token")
        let isAccessable =  cookies.get('isAccessable')
        let ivrRecordingAccess =  cookies.get('ivrRecordingAccess')
        console.log(isAccessable,'isAccessable');
        if (userToken) {
            props.setToken(userToken)
        }
        if(isAccessable){
            props.setIsAccessable(isAccessable)

        }
        if(ivrRecordingAccess){
            props.setIvrRecordingAccess(ivrRecordingAccess)
        }
    }, [])
    console.log(token, 'token');
    return (
        <Aux>
            {/* <Route exact path='/dashboard/defaults' render={() => (<DashboardDefaults />)} />
                                                    <Route exact path='/dashboard/ivr' render={() => (<DashboardIvr />)} />
                                                    <Route exact path='/dashboard/webform' render={() => (<DashboardWebform />)} />
                                                    <Route exact path='/dashboard/agentFeedback' render={() => (<DashboardAgentFeedback />)} /> */}
            {/* <Route exact path='/auth/signin-1' render={() => (<SignIn1   {...props}/>)} /> */}
            {/* <HashRouter>
                <Switch> */}
                    {/* {token ? <AdminLayout {...{ token, cookies }} {...props} /> : */}
                        {/* <ScrollToTop>
                            <Suspense fallback={<Loader />}>

                                <Route exact path='/auth/signin-1' render={() => (<SignIn1   {...props} />)} />
                                <Route exact path='/' render={() => (<SignIn1   {...props} />)} /> */}
                                {/* <Route path="/" name="Home" component={(props)=><AdminLayout {...{ token, cookies }} {...props} />} /> */}
                                {/* <Route  exact path='/auth/signup-1' render={() => (<SignUp1   {...props}/>)} /> */}
                                {/* <Route render={() => (<Redirect from="/" to='/auth/signin-1' />)} /> */}
                                
          {/* <PrivateRoute path="/" name="Dashboard" component={<AdminLayout {...{ token, cookies }} {...props} />} /> */}

                            {/* </Suspense> */}
                        {/* </ScrollToTop> */}
                        {/* } */}
                {/* </Switch></Router> */}

                <HashRouter>
        <Switch>
          <Route exact path="/auth/signin-1" name="Login Page" component={SignIn1} />
          <PrivateRoute path="/" name="Dashboard" component={AdminLayout} />
        </Switch>
      </HashRouter>
        </Aux>
    );
}



const mapStateToProps = (state) => {
    return {
        token: state.users.token
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { setToken,setIsAccessable,setIvrRecordingAccess },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(withCookies(App)));
