

  

    export default {
 
        items: [
            {
            id: 'admin',
            title: 'Admin',
            type: 'group',
            icon: 'icon-user',
            children: [
                {
                    id: 'auth',
                    title: 'Admin',
                    type: 'collapse',
                    icon: 'feather icon-user',
                    children: [
                        {
                            id: 'tabs-pills',
                            title: 'IVR List',
                            type: 'item',
                            url: '/dashboard/ivr-list',
                            icon: 'feather icon-list',
                        }, 
                        {
                            id: 'tabs-pills',
                            title: 'Setting',
                            type: 'item',
                            url: '/dashboard/setting',
                            icon: 'feather icon-settings',
                        }, 
                    ]
                }
             
            ]
        },
        {
            id: 'navigation',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard/defaults',
                    icon: 'feather icon-home',
                },
                {
                    id: 'button',
                    title: 'IVR Feedback',
                    type: 'item',
                    url: '/dashboard/ivr',
                    icon: 'feather icon-phone',
                },
                
                    {
                        id: 'collapse',
                        title: 'Agent Feedback',
                        type: 'item',
                        url: '/dashboard/agentFeedback',
                        icon: 'feather icon-sidebar'
                    },
                    {
                        id: 'tabs-pills',
                        title: 'Webform',
                        type: 'item',
                        url: '/dashboard/webform',
                        icon: 'feather icon-file-text'
                    }, 
                  
                    
            ]
        },
        
    ]
}